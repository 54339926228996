import React from 'react';
import { Grid, Card, CardActionArea, CardContent, Typography } from '@mui/material';

const features = [
  { id: 'guidebook', title: '가이드북', icon: 'assets/icons/menu_icon_guidebook.png' },
  { id: 'qna', title: '질문답변', icon: 'assets/icons/menu_icon_qna.png' },
  { id: 'calculator', title: '패스계산기', icon: 'assets/icons/menu_icon_path.png' },
  { id: 'coupon', title: '할인쿠폰', icon: 'assets/icons/menu_icon_coupon.png' }
];

function MenuBar() {
  return (
    <Grid container spacing={2} >
      {features.map(feature => (
        <Grid item xs={3} key={feature.id}>
          <Card sx={{ boxShadow: 'none', border: 0}}> 
            <CardActionArea>
              <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '84px' }}>
                <img src={feature.icon} alt={feature.title} style={{ width: 62, height: 62, marginBottom: 5}} />
                <Typography sx={{ fontSize: 14, fontWeight:500, whiteSpace: 'nowrap',}}>
                  {feature.title}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default MenuBar;