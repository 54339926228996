import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Drawer, Typography, Button, Avatar, Box, Grid, Modal, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProfiles, fetchFollowers, fetchAddFollower, fetchDeleteFollower } from '../../repository/profiles/profilesRepository';
import PostCard from '../../common/components/PostCard';
import BottomNav from '../../common/components/BottomNav';
import Puller from '../../common/components/Puller';

const ProfilePage = () => {
  return <Box>
    <ProfileContent/>
    <Toolbar/>
    <BottomNav/>
  </Box>
}

function ProfileContent() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user_id } = useParams();

    const user = useSelector((state) => state.profiles.user);
    const posts = useSelector((state) => state.profiles.posts);
    const follower_count = useSelector((state) => state.profiles.followed_count);
    const followers = useSelector((state) => state.profiles.followers);
    const loginUserId = useSelector((state) => state.user.userId);
    const is_follow = useSelector((state) => state.profiles.is_follow);

    const [drawerOpen, setDrawerOpen] = useState(false);
    
    useEffect(() => {
      window.scrollTo(0, 0); // 스크롤을 맨 위로 초기화
      if (user_id) {
        fetchProfiles(dispatch, user_id);
        // 팔로워 데이터를 fetch하는 함수 (예시)
        fetchFollowers(dispatch, user_id);
      }
    }, [user_id, dispatch]);
  
    const handleFollower = (is_follow) => {
      is_follow ? fetchDeleteFollower(dispatch, user_id) : fetchAddFollower(dispatch, user_id);
    }

    const handleFollowerClick = () => {
      setDrawerOpen(true);
    };
  
    const handleCloseFollowerModal = () => {
      setDrawerOpen(false);
    };
  
    const handleClose = () => {
      navigate("/main");
    };
  
    const handleEditProfile = () => {
      navigate(`/profile/${user_id}/edit`);
    };
  
    return (
      <Box sx={{
        '@media (min-width: 600px)': {
          maxWidth: '600px',
          padding: '0',
          margin: '0 auto',
        }
      }}>
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '194px',
          backgroundImage: 'url(/assets/images/bg_test.jpeg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1
        }} />
        <Box sx={{ p: '0 16px 16px 16px', top: 0 }}>
          <AppBar position="relative" color="transparent" sx={{
            elevation: 0,
            p: 1,
            margin: 0,
            boxShadow: 'none',
            border: 0,
            zIndex: 10
          }}>
            <Toolbar sx={{ justifyContent: 'space-between', p: 0, margin: 0 }}>
              {/* <img src="/assets/images/bg_test.jpeg" alt="back_button"/> */}
              {/* <IconButton edge="start" color="inherit" aria-label="back" onClick={() => (handleClose())}>
                <img src="/assets/icons/back_button.png" alt="back_button" style={{ width: '10px', height: '17px' }} />
              </IconButton> */}
            </Toolbar>
          </AppBar>
          <Box sx={{ height: '57px' }} />
          <Avatar sx={{ width: 64, height: 64, marginBottom: '12px', border: 1.5, borderColor: 'rgba(255,255,255,1)' }} src={user.profile_image_url} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'rgba(0,0,0,1)' }}>{user.username}</Typography>
              <Typography onClick={handleFollowerClick} sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(115,115,115,1)', cursor: 'pointer' }}>
                팔로워 {follower_count}
              </Typography>
            </Box>
            {user.id === loginUserId ? (
              <Button 
                variant="outlined" 
                onClick={handleEditProfile}
                sx={{
                  color: 'rgba(0,0,0,0.85)',
                  borderColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '20px',
                  width: '100px',
                  height: '36px',
                  fontSize: '14px',
                  fontWeight: '500'
                }}
              >
                프로필 수정
              </Button>
            ) : (
              is_follow ? <FollowButton onClick={()=> handleFollower(is_follow)}/> : 
              <Button
                variant="contained"
                sx={{
                  backgroundColor: is_follow ? 'rgba(220, 0, 78, 1)' : 'rgba(58,139,255,1)',
                  color: '#fff',
                  borderRadius: '8px',
                  width: '61px',
                  height: '36px',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '1',
                  padding: '8px 0',
                }}
                onClick={()=> handleFollower(is_follow)} 
              >
                팔로우
              </Button>
            )}
          </Box>
  
          {/* 팔로워 모달 */}
          <DrawerFollowList followers={followers} drawerOpen={drawerOpen} closeDrawer={handleCloseFollowerModal}/>
          {/* <Modal open={isFollowerModalOpen} onClose={handleCloseFollowerModal}>
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              maxWidth: '400px',
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              p: 2,
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6">팔로워 {follower_count}</Typography>
              </Box>
              <List>
                {followers.map((follower) => (
                  <ListItem key={follower.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ListItemAvatar>
                      <Avatar src={follower.user.profile_image_url} />
                    </ListItemAvatar>
                    <ListItemText primary={follower.user.username} />
                    <Button variant="outlined" sx={{
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '20px',
                      minWidth: '70px',
                      height: '30px',
                      fontSize: '12px',
                    }}>팔로잉</Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Modal> */}
  
          <Box>
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgba(0,0,0,0.75)' }}>
              {/* 소개글 */}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: 'rgba(255, 255, 255, 1)' }} />
        <Box>
          <Grid container>
            {posts && posts.length > 0 ? (
              posts.map((post) => (
                <Grid item xs={12} sm={12} md={12} lg={12} key={post.id}>
                  <PostCard post={post} />
                </Grid>
              ))
            ) : (
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)', padding: '16px' }}>
                게시물이 없습니다.
              </Typography>
            )}
          </Grid>
        </Box>
        <Toolbar />
      </Box>
    );
  }
  
const FollowButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        backgroundColor: 'rgba(239, 243, 251, 1)',
        color: 'rgba(58, 139, 255, 1)',
        fontWeight: 600,
        fontSize: '14px',
        borderRadius: '6px',
        padding: '6px 16px',
        minWidth: 'auto',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'rgba(229, 233, 241, 1)',
          boxShadow: 'none',
        },
      }}
    >
      팔로잉 ✓
    </Button>
  );
};

const DrawerFollowList = ({followers, drawerOpen, closeDrawer}) => {
  return <>
  <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: 32, // Top left border radius
            borderTopRightRadius: 32, // Top right border radius
            borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF', // Background color of the drawer
            height: 'auto', // Adjust this value as needed to cover the bottom nav
            overflow: 'visible'
          }
        }}
      >
         <Puller/>
         <Box sx={{ height: 16 }} />
         <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>팔로워</Typography>
        </Box>
         <List sx={{marginBottom:'19px'}}>
            {followers.map((follower) => (
                  <ListItem key={follower.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ListItemAvatar>
                      <Avatar src={follower.user.profile_image_url} />
                    </ListItemAvatar>
                    <ListItemText primary={follower.user.username} />
                    <Button variant="outlined" sx={{
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '20px',
                      minWidth: '70px',
                      height: '30px',
                      fontSize: '12px',
                    }}>팔로잉</Button>
                  </ListItem>
            ))}
           {/* <ListItem button sx={{ height: 71, justifyContent: 'center', display: 'flex' }}>
             <ListItemText primary={<Typography sx={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>피드 남기기</Typography>} />
           </ListItem> */}
           {/* <Divider component="li" />
           <ListItem button onClick={handleQuestionChange} sx={{ height: 71, justifyContent: 'center', display: 'flex' }}>
             <ListItemText primary={<Typography sx={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>질문하기</Typography>} />
           </ListItem> */}
         </List>
       </Drawer>
  </>;
}

export default ProfilePage;
