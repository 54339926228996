import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
  }
});

api.interceptors.request.use(
    config => {
      // '/api/auth'가 포함된 URL이면 Authorization 헤더를 제외
    //   if (!config.url.includes('/api/auth')) {
        // 로컬 스토리지에서 authToken을 가져오기
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
          // Authorization 헤더에 토큰 추가
          config.headers['Authorization'] = `${authToken}`;
        }
    //   }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

api.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        if (error.response.status === 401) {
            // 에러 메시지 확인
            const errorMessage = error.response.data.message;
            console.log(errorMessage)
            // "Access token is missing" 메시지가 있을 경우 재시도하지 않고 에러 반환
            if (errorMessage.includes("missing")) {
                return Promise.reject(error);
            }

            // _retryCount 프로퍼티를 초기화하거나 증가
            originalRequest._retryCount = (originalRequest._retryCount || 0) + 1;

            // 최대 2번까지만 재시도 허용
            if (originalRequest._retryCount <= 2) {
                return api.get('/api/auth/tokens/refresh')
                    .then(res => {
                        if (res.status === 200) {
                            // 토큰 새로 고침 후 원래 요청 재시도
                            return api(originalRequest);
                        }
                        return Promise.reject('Failed to refresh token');
                    }).catch(refreshError => {
                        return Promise.reject(refreshError);
                    });
            }
        }

        // 최대 재시도 횟수를 초과하거나 다른 조건에 해당하는 경우, 에러를 반환
        return Promise.reject(error);
    }
);

export default api;

