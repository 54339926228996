// CustomSnackbar.js
import React from 'react';
import { Snackbar, IconButton, Button, Typography  } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const StyledSnackbar = styled(Snackbar)({
  '& .MuiPaper-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: 'white',
    borderRadius: '8px',
    height: '48px',
    fontWeight: 600,
    fontSize: '14px',
    paddingTop: 0,
    paddingBottom: 0,
    opacity: 0.75,
  }
});

const CustomSnackbar = ({ open, onClose, message, autoHideDuration = 3000, actionIcon, actionRoute }) => {
  const navigate = useNavigate();

  const handleActionClick = () => {
    if (actionRoute) {
      navigate(actionRoute);
    }
    onClose();
  };


  const LoginButton = () => {
    return (
      <Button
        variant="text"
        sx={{
          padding: 0,
          minWidth: 'auto',
          color: 'white',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        }}
        onClick={()=> navigate('/login')}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '500',
            textDecoration: 'underline', // 텍스트 아래 밑줄 추가
            textUnderlineOffset: '3px',  // 밑줄 위치 조정
          }}
        >
          로그인
        </Typography>
      </Button>
    );
  }

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={onClose}
      message={message}
      sx={{ bottom: '75px', 
        marginLeft: '10px', 
        marginRight: '10px',        
        bottom: '75px',
      }}
      action={
        actionIcon && (
          <React.Fragment>
            {actionRoute == '/login' ? <LoginButton/> : <IconButton
              aria-label="action"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleActionClick}
            >
              <img src={actionIcon} alt="Action icon" />
            </IconButton>}
          </React.Fragment>
        )
      }
    />
  );
};

export default CustomSnackbar;
