import React, { useState, useEffect, useRef}  from 'react';
import { Card, CardContent, CardActions, Typography, IconButton, Avatar, Box, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { styled } from '@mui/material/styles';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import PostCardText from './PostCardText';
import CommentDrawer from '../../features/comment/CommentDrawer';
import LocationCard from './LocationCard';

import {icons} from '../../constants/icons';
import {formatTime} from '../../util/utils';
import api from '../../api/apiClient';
import {updateFeedById} from '../../repository/posts/postsSlice';
import { clearComments } from '../../repository/comments/commentsSlice';
import {postBookmarks, deleteBookmarks} from '../../api/SwissApi';
import CustomSnackbar from './exception/CustomSnackbar';
import ArtTextComponent from './ArtTextComponent';
import PostImageDetail from './PostImageDetail';

const CustomSwiper = styled(Swiper)({
  '& .swiper-pagination': {
    left: 0,
    right: 0,
    top: '380px',
    textAlign: 'center',
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none', // 페이지네이션 요소에 대한 포인터 이벤트 비활성화
    '& .swiper-pagination-bullet': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      pointerEvents: 'auto' // 페이지네이션 버튼은 클릭 가능하게 설정
    },
    '& .swiper-pagination-bullet-active': {
      backgroundColor: '#FFFFFF',
    }
  },
});

const StyledSwiper = {
  height: '400px',
  paddingTop: '12px',
  // borderRadius: '30px', // Rounded corners with 8px radius
};
function PostCard({ post }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.userId);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [commentDrawerOpen, setCommentDrawerOpen] = useState(false);

  const [isLiked, setIsLiked] = useState(false);
  const [isLocationBookmarked, setIsLocationBookmarked] = useState(false);
  const [isPostBookmarked, setIsPostBookmarked] = useState(false);

  // 모달 상태 추가
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  // Snackbar 상태를 하나의 객체로 관리
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    actionIcon: null,
    actionRoute: '',
  });

  useEffect(()=>{
    console.log('[postcard] post:',post);
    setIsLiked(post.is_liked);
    setIsPostBookmarked(post.is_posts_bookmarked);
  },[post])

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const closeDrawer = () => {
    // 댓글 초기화
    dispatch(clearComments());
    setCommentDrawerOpen(false);
  }
  const swiperRef = useRef(null);

  const updateUI = () => {
      const swiper = swiperRef.current.swiper;
      if (swiper) {
          swiper.updateAutoHeight(500); // 100ms 후에 자동 높이 조정
      }
  };
  const handleBookmark = () => {
    if (!userId) {
      handleLoginAlert();
      return;
    }
    
    setIsPostBookmarked(!isPostBookmarked);
    if (!isPostBookmarked) {
      setSnackbarState({
        open: true,
        message: "북마크에 추가했어요!",
        actionIcon: "assets/icons/white_right_arrow.png",
        actionRoute: "/bookmarks?type=post"
      });
      postBookmarks('post', post.id).then(res => console.log(res.status));
    } else {
      setSnackbarState({
        open: true,
        message: "북마크에서 삭제했어요.",
        actionIcon: null,
        actionRoute: ""
      });
      deleteBookmarks('post', post.id).then(res => console.log(res.status));
    }
  };

  const handleLike = () => {
    if (!userId) {
      handleLoginAlert();
      return;
    }

    setIsLiked(!isLiked);
    if (isLiked) {
      api.delete(`/api/posts/${post.id}/likes`)
        .then(res => {
          dispatch(updateFeedById({ id: post.id, is_liked: false }));
        })
        .catch(err => console.log(err));
    } else {
      api.post(`api/posts/${post.id}/likes`)
        .then(res => {
          dispatch(updateFeedById({ id: post.id, is_liked: true }));
        })
        .catch(err => console.log(err));
    }
  };

  const handleLoginAlert = () => {
    setSnackbarState({
      open: true,
      message: "로그인이 필요해요. 👉🏻",
      actionIcon: "/assets/icons/login_icon.png",
      actionRoute: "/login"
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState(prevState => ({ ...prevState, open: false }));
  };

  // Web Share API를 사용하는 공유 함수
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: post.title || '게시물 공유',
          text: post.posts[currentSlide].content,
          url: `${window.location.origin}/posts/${post.id}`, // 현재 페이지 URL 공유
        })
        .then(() => {
          console.log('공유 성공');
        })
        .catch((error) => {
          console.log('공유 실패', error);
        });
    } else {
      console.log('Web Share API가 지원되지 않습니다.');
    }
  };


  const onMoveProfiles = (userId) => {
    navigate(`/profiles/${userId}`);
  };
  return (
    <>
    <Card key={`postcard-${post.id}`} sx={{ display: 'flex', flexDirection: 'column', border: 'none', boxShadow: 'none'}} >
      <Box sx={{ p: 2, pb: '0px'  }}>  
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={()=> onMoveProfiles(post.user.id)}>
          <Avatar src={post?.user?.profile_image_url} sx={{ width: 44, height: 44, marginRight: 1.5 }} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontSize: '15px', fontWeight: 600 }}>{post?.user?.username}</Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#737373' }}>{formatTime(post.created_at)}</Typography>
          </Box>
        </Box>
      </Box>
      <CardContent sx={{ padding: 0 }}>
        <Box>
          <CustomSwiper
          ref={swiperRef}
          slidesPerView={1}
          spaceBetween={0}
          centeredSlides={false}
          pagination={{
            clickable: true,
          }}
          autoHeight={true}
          onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
          modules={[Pagination]}
          className="postSwiper">
        {post?.posts?.map((postInfo, index) => {
          return  <>
            <SwiperSlide key={`${postInfo.id}-${index}`} >
              {
                postInfo.image_url && postInfo.image_url !== "" && 
                <img src={postInfo.image_url} style={{...StyledSwiper, cursor: 'pointer'}} onClick={() => handleImageClick(postInfo.image_url)}/>
              }
              {
                post.posts[currentSlide].art &&     
                <ArtTextComponent title={post.posts[currentSlide].art.title} artist={post.posts[currentSlide].art.artist} selectedImage={postInfo.image_url}/>
              }
              <PostCardText content={postInfo.content} rating={postInfo.rating} swiperRef={swiperRef}/>
            </SwiperSlide>
          </>
        })}  
      </CustomSwiper>
      {
        post.posts && 
        post.posts[currentSlide] && 
        post.posts[currentSlide].location &&
        <Box sx={{pl:2, pr:2, pb:'10px'}}>
          <LocationCard location={post.posts[currentSlide].location} readOnly={true} isLocationBookmarked={post.posts[currentSlide].is_locations_bookmarked} setSnackbarState={setSnackbarState} handleLoginAlert={handleLoginAlert}/>
        </Box>
      }
        </Box>
      </CardContent>
      <CardActions sx={{ mt: 'auto', justifyContent: 'space-between', pt:0 }} disableSpacing>
      <Box sx={{ display: 'flex' }}> 
        <IconButton onClick={() => handleLike(post)} aria-label="add to favorites">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={isLiked ? icons.like.active : icons.like.inactive} alt="Like" style={{ width: 20, height: 20 }} />
            <Typography sx={{fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)', marginLeft: 1}}>
            {post.likes_count || ''} {/* 좋아요 수 */}
            </Typography>
          </Box>
        </IconButton>
        <IconButton aria-label="comment" onClick={()=>{setCommentDrawerOpen(true)}}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={icons.comments.active} alt="Comment" style={{ width: 20, height: 20 }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)',pl:'6px' }}>
            {post.comments_count || '' } {/* 댓글 수 */}
           </Typography>
          </Box>
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <IconButton aria-label="share" sx={{ marginRight: '6px' }} onClick={()=> handleShare()}>
          <img src={icons.share.active} alt="Share" style={{ width: 20, height: 20 }} />
        </IconButton>
        <IconButton onClick={() => handleBookmark()} aria-label="bookmark">
          <img src={isPostBookmarked ? icons.smallBookmarks.active : icons.smallBookmarks.inactive} alt="Bookmark" style={{ width: 20, height: 20 }} />
        </IconButton>
      </Box>
    </CardActions>
    <CustomSnackbar
        open={snackbarState.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
        actionIcon={snackbarState.actionIcon}
        actionRoute={snackbarState.actionRoute}
      />
    <Divider variant="middle" sx={{borderBottomWidth: '2px' }}/>
    <CommentDrawer postGroupId={post.id} open={commentDrawerOpen} handleClose={closeDrawer}/>
    </Card>
    <PostImageDetail selectedImage={selectedImage} modalOpen={modalOpen} setModalOpen={setModalOpen}/>
    </>
  );
}

export default PostCard;
