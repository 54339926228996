import { createSlice } from '@reduxjs/toolkit';

export const commentsSlice = createSlice({
  name: 'comments',
  initialState: {
    is_reply_to: 0,
    comments: [],
  },
  reducers: {
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    addComments: (state, action) => {
      state.comments.push(action.payload);
    },
    setIsReply: (state, action) => {
      state.is_reply_to = action.payload;
    },
    clearComments: (state, action) => {
      state.comments = [];
    }
  },
});

// Action creators are generated for each case reducer function
export const { setComments, addComments, clearComments, setIsReply } = commentsSlice.actions;

export default commentsSlice.reducer;