import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

const OwnerProtectedRoute = ({ children }) => {
  const { user_id } = useParams();
  const loginUserId = useSelector((state) => state.user.userId);
  
  // 로그인하지 않았거나, 프로필 소유자가 아닌 경우
  if (!loginUserId || loginUserId !== parseInt(user_id)) {
    return <Navigate to="/main" replace />;
  }

  return children;
};

export default OwnerProtectedRoute;