import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../api/apiClient';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { Box, Typography, Grid, Card, CardActionArea, CardContent } from '@mui/material'; // Box와 Typography 추가
import { useNavigate, useSearchParams } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';

const GoogleLocationButton = ({ onClick }) => {
    return (
        <Grid container>
            <Grid item xs={12}>  {/* 이제 이 Grid 아이템은 전체 너비를 차지합니다. */}
                <Card sx={{ 
                    width: '100%',  // Card의 너비를 100%로 설정
                    boxShadow: 'none', 
                    border: 0,
                    borderRadius: '12px', // Border radius consistent on all states
                    '&:hover': {
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional hover effect
                    }
                }}> 
                    <CardActionArea onClick={onClick}>
                        <CardContent sx={{ 
                            textAlign: 'center', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center',  // 오타 수정: 'malignItems' -> 'alignItems'
                            height: '56px', 
                            padding: 0, 
                            backgroundColor: '#F5F7F8', 
                            borderRadius: '12px'
                        }}>
                            <Typography sx={{ 
                                fontSize: '16px', 
                                fontWeight: 600, 
                                color: 'rgba(0, 0, 0, 0.85)'
                            }}>
                                구글에서 장소 등록
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
    );
}

function PlaceSearch() {
  const [query, setQuery] = useState('');
  const [places, setPlaces] = useState([]);
  const [searchParams] = useSearchParams();
  const contentIndex = searchParams.get('content_index');

  const navigate = useNavigate();
  useEffect(() => {
    if (query.length > 0) {
      api.get('/api/search/google/places', {
        params: { input: query },
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      .then(response => {
        setPlaces(response.data.candidates);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  }, [query]);

  const handleClose = () => {
    navigate(-1);
  };
  const MoveToGoogleMap = () => {
    navigate(`/search/places/google?content_index=${contentIndex}`);
  };
  return (
    <Box>
      <AppBar position="static" 
      sx={{
        paddingLeft:'8px',
        margin:0,                 
        backgroundColor: '#ffffff', 
        elevation: 0, // Remove shadow
        boxShadow: 'none', // Additional reinforcement to remove shadows
        border: 0 }}>
        <Toolbar>
          <IconButton onClick={handleClose} edge="start" color="inherit" aria-label="back" >
            <img src="/assets/icons/back_button.png" alt="back_button" style={{ width: '10px', height: '17px'}} />
          </IconButton>
          <Box sx={{width:26}}/>
          <TextField
            placeholder="장소를 검색하세요"
            value={query}
            onChange={e => setQuery(e.target.value)}
            sx={{
                width: '100%', // 필요하면 너비 조정
                '.MuiInputBase-root': {
                height: '44px',
                borderRadius: '12px',
                backgroundColor: 'rgba(245, 247, 248, 1)'
                },
                '.MuiOutlinedInput-notchedOutline': {
                border: 'none' // 외곽선 제거
                },
                '.MuiInputBase-input': {
                height: '100%',
                padding: '0 14px' // 좌우 패딩 조정 (아이콘 공간 등 고려)
                }
            }}
            InputProps={{
                endAdornment: (
                <IconButton color="inherit">
                    <img src="/assets/icons/search.png" alt="search" style={{ width: '24px', height: '24px'}} />
                </IconButton>
                )
            }}/>
        </Toolbar>
      </AppBar>
      <Box sx={{p:2, pt:'30px'}}>
        <Box sx={{color:'rgba(0, 0, 0, 0.6)'}}>
            <Typography sx={{fontSize:'14px', fontWeight:500}}>
                찾는 장소가 없다면?
            </Typography>
        </Box>
        <Box sx={{height:14}}/> 
        <GoogleLocationButton onClick={MoveToGoogleMap}/>
      </Box>
      {/* <List component="nav" aria-label="main mailbox folders">
        {places.map(place => (
        <ListItem button key={place.place_id}>
            <ListItemText primary={place.name} secondary={place.formatted_address} />
            <ListItemIcon>
                <IconButton onClick={() => handleMapOpen(place)} edge="end" aria-label="map">
                    <MapIcon />
                </IconButton>
            </ListItemIcon>
        </ListItem>
        ))}
      </List> */}
    </Box>
  );
}

export default PlaceSearch;