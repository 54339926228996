import React, { useState } from 'react';
import { AppBar, Toolbar, Box, TextField, Button, Rating, Typography, IconButton, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ImageUpload from './ImageUpload';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function RestaurantReviewForm() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [rating, setRating] = useState(2.5);
  const [reviewText, setReviewText] = useState('');
  const [selectedDate, setSelectedDate] = useState(null); // 일정을 저장할 새로운 state

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleReviewTextChange = (event) => {
    setReviewText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // 폼 제출 로직을 여기에 구현하세요.
    console.log({
      searchQuery,
      rating,
      reviewText
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClose = () => {
    // 취소 로직 구현, 예를 들어 폼을 닫거나 이전 페이지로 이동
    navigate(-1);
  };
  return <Paper component="form" onSubmit={handleSubmit} sx={{ p: 2, }}>
        <AppBar position="static" color="default" elevation={0} sx={{ bgcolor: 'white' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ color: 'black' }}>
            <CloseIcon />
          </IconButton>
          <Button color="inherit" onClick={handleSubmit} sx={{ color: 'black' }}>
            등록
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <TextField
          fullWidth
          placeholder="레스토랑 이름을 검색하세요"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton type="submit">
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
          <Typography component="legend">평점</Typography>
          <Rating
            name="restaurant-rating"
            value={rating}
            onChange={handleRatingChange}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
          <Typography component="legend">일정</Typography>
          <DatePicker
            label="일정 선택"
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </LocalizationProvider>
      <TextField
        fullWidth
        multiline
        rows={6}
        placeholder="리뷰를 작성하세요"
        value={reviewText}
        onChange={handleReviewTextChange}
        variant="outlined"
      />
      <ImageUpload/>
    </Paper>
}

export default RestaurantReviewForm;