import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Container, TextField, Grid, Select, MenuItem, InputLabel, FormControl, Typography, Button, Box, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import api from '../../api/apiClient';

const ContentManagePage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [titleFilter, setTitleFilter] = useState('title');
  const [secondTitleFilter, setSecondTitleFilter] = useState('');
  const [publishStatus, setPublishStatus] = useState('all');
  const [contentCategory, setContentCategory] = useState('');
  const [secondContentCategory, setSecondContentCategory] = useState('');

  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleNavigate = (id) => {
    // Navigate to a different page, e.g., detail page for the post
    navigate(`/posts/detail/${id}`);
  };
  const handlePublish = () => {
    // API call or data manipulation to publish selected items
    console.log('Publish:', selectionModel);
  };

  const handleHide = () => {
    // API call or data manipulation to hide selected items
    console.log('Hide:', selectionModel);
  };

  const handleDelete = () => {
    // API call or data manipulation to delete selected items
    console.log('Delete:', selectionModel);
  };

  const fetchData = async () => {
    try {
        const response = await api.get('/api/posts', { withCredentials: true });
        setData(response.data);
    } catch (error) {
        console.error('User is not logged in');
        // 로그인 페이지로 리다이렉트 혹은 로그인 상태 업데이트
    }
    // const result = await axios.get('YOUR_API_URL', {
    //   params: {
    //     title: titleFilter,
    //     secondTitle: secondTitleFilter,
    //     publishedStatus: publishStatus,
    //     category: contentCategory,
    //     secondCategory: secondContentCategory
    //   }
    // });
  };

  const handleSearch = () => {
    fetchData();
  };

  const columns = [
    { field: 'id', 
      headerName: 'No', 
      width: 80,
      renderCell: (params) => (
        <Button 
          color="primary" 
          onClick={() => handleNavigate(params.value)}
        >
          {params.value}
        </Button>
      ), 
    },
    { field: 'content_type', 
      headerName: '구분', 
      width: 100,
      valueGetter: (params) => params ? '자동 생성' : '유저 생성'
    },
    { field: 'post_type', headerName: '카테고리', width: 100 },
    { field: 'title', headerName: '제목', width: 280 },
    { 
        field: 'user', 
        headerName: '작성자', 
        width: 180, 
        valueGetter: (params) => params.username // Safely access username
    },
    {
        field: 'published',
        headerName: '발행',
        width: 50,
        renderCell: (params) => (params.value ? 'Y' : 'N') // 조건부 렌더링
    },
    { field: 'performance', headerName: '불확실성', type: 'number', width: 60 },
    { field: 'created_date', headerName: 'Created Date', width: 120 },
    { field: 'updated_date', headerName: 'Modified Date', width: 120 }
  ];

  return (
    <Container maxWidth="lg">
      <Typography variant="h5" gutterBottom>
        컨텐츠 관리
      </Typography>
      <Paper variant="outlined" style={{ padding: '20px', marginBottom: '20px' }}>
        <Grid container spacing={0} direction="column">
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box width="10%" mr={2}>
                <Typography>검색 구분</Typography>
              </Box>
              <FormControl fullWidth sx={{ maxWidth: 250, marginRight: 2 }}>
                <InputLabel id="content-category-label">선택</InputLabel>
                <Select
                  labelId="content-category-label"
                  value={titleFilter}
                  onChange={e => setTitleFilter(e.target.value)}
                >
                  <MenuItem value="title">제목</MenuItem>
                  <MenuItem value="username">닉네임</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                sx={{ maxWidth: 500 }}
                value={secondTitleFilter}
                onChange={e => setSecondTitleFilter(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box width="10%" mr={2}>
                <Typography>발행 상태</Typography>
              </Box>
              <FormControl fullWidth sx={{ maxWidth: 250 }}>
                <InputLabel id="publish-status-label">선택</InputLabel>
                <Select
                  labelId="publish-status-label"
                  value={publishStatus}
                  onChange={e => setPublishStatus(e.target.value)}
                >
                  <MenuItem value="all">전체</MenuItem>
                  <MenuItem value="true">발행</MenuItem>
                  <MenuItem value="false">미발행</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box width="10%" mr={2}>
                <Typography>컨텐츠 구분</Typography>
              </Box>
              <FormControl fullWidth sx={{ maxWidth: 250, marginRight: 2 }}>
                <InputLabel id="content-category-label">선택</InputLabel>
                <Select
                  labelId="content-category-label"
                  value={contentCategory}
                  onChange={e => setContentCategory(e.target.value)}
                >
                  <MenuItem value="all">전체</MenuItem>
                  <MenuItem value="auto">자동 생성</MenuItem>
                  <MenuItem value="user">유저 생성</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ maxWidth: 250 }}>
                <InputLabel id="second-content-category-label">선택</InputLabel>
                <Select
                  labelId="second-content-category-label"
                  value={secondContentCategory}
                  onChange={e => setSecondContentCategory(e.target.value)}
                >
                  <MenuItem value="all">전체</MenuItem>
                  <MenuItem value="free">자유글</MenuItem>
                  <MenuItem value="restaurant">맛집리뷰</MenuItem>
                  <MenuItem value="accommodation">숙소리뷰</MenuItem>
                  <MenuItem value="tourist">관광지리뷰</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button variant="contained" onClick={handleSearch}>검색</Button>
          </Grid>
        </Grid>
      </Paper>
      <div style={{ height: 400, marginTop: 20 }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          onSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
          pagination
        />
      </div>
      <Box display="flex" justifyContent="flex-start" mt={2}>
        <Button variant="contained" color="primary" onClick={handlePublish} sx={{ marginRight: 1 }}>
          발행
        </Button>
        <Button variant="contained" color="secondary" onClick={handleHide} sx={{ marginRight: 1 }}>
          숨김처리
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          삭제
        </Button>
      </Box>
    </Container>
  );
};

export default ContentManagePage;