import { getPostDetail, getFeedList, getMoreFeedList, getPostPopular, postFeed } from '../../api/SwissApi';
import { setPostDetail, setFeedList, moreFeedList, setPopular, morePopular, clearEditors } from './postsSlice';

export const fetchPostFeed = async (dispatch, feed) => {
    try {
        const response = await postFeed(feed);
        console.log(response);
        dispatch(clearEditors());
    } catch (error) {
        console.error('Failed to fetch feed:', error);
    }
}

export const fetchGetFeedDetail = async (dispatch, postGroupId) => {
    try {
        const postDetail = await getPostDetail(postGroupId);
        dispatch(setPostDetail(postDetail));

    } catch (error) {
        console.error('Failed to fetch post detail:', error);
    }
};

export const fetchFeedList = async (dispatch, type='feed') => {
    try {
        const feedList = await getFeedList(type);
        dispatch(setFeedList(feedList));

    } catch (error) {
        console.error('Failed to fetch feed list:', error);
    }
}
export const fetchMoreFeedList = async (dispatch, nextUrl) => {
    try {
        const feedList = await getMoreFeedList(nextUrl);
        dispatch(moreFeedList(feedList));

    } catch (error) {
        console.error('Failed to fetch feed list:', error);
    }
}

export const fetchPostPopular = async (dispatch) => {
    try {
        const postPopularList = await getPostPopular();
        dispatch(setPopular(postPopularList));

    } catch (error) {
        console.error('Failed to fetch feed list:', error);
    }
}

export const fetchMorePostPopular = async (dispatch, nextUrl) => {
    try {
        const postPopularList = await getMoreFeedList(nextUrl);
        dispatch(morePopular(postPopularList));

    } catch (error) {
        console.error('Failed to fetch feed list:', error);
    }
}