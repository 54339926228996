import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Tabs, Tab, Box, Typography, Grid,IconButton, Toolbar, } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom'; 
import PostCard from '../../../common/components/PostCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetFeedDetail, fetchPostPopular, fetchMorePostPopular } from '../../../repository/posts/postsRepository'
function PostDetailPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const postDetail = useSelector((state) => state.posts.detail);
  const posts = useSelector((state) => state.posts.popular);
  const next = useSelector((state) => state.posts.popular_next);

  const [isLoading, setIsLoading] = useState(false);
  const { post_group_id } = useParams();

  const currentTab = new URLSearchParams(location.search).get('tab') || 'home';

  const handleClose = () => {
    navigate('/main');
  }
  const fetchMorePosts = useCallback(async () => {
    if (isLoading || next === null) return; // Stop if loading or no more pages

    try {
      fetchMorePostPopular(dispatch, next);
    } catch (error) {
      console.error('Failed to load more posts:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, next, dispatch]);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 5 >= document.documentElement.offsetHeight) {
        fetchMorePosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchMorePosts]);
//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.innerHeight + document.documentElement.scrollTop+5 >= document.documentElement.offsetHeight || isLoading) return;
//       //fetchMorePosts();
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [fetchMorePosts, isLoading]);

  useEffect(() => {
    console.log("post_group_id: ", post_group_id)
    window.scrollTo(0, 0); // 스크롤을 맨 위로 초기화
    fetchGetFeedDetail(dispatch, post_group_id).then(()=>{
        console.log('ttt',postDetail);
    });
    fetchPostPopular(dispatch);
  }, [post_group_id]);

  return (
    <Box
        sx={{
        width: '100%',
        padding: '0', // 기본 padding
        boxSizing: 'border-box',
        '@media (min-width: 600px)': {
            maxWidth: '600px',  // 최대 너비
            padding: '0',  // 너비가 1200px 이상일 때 padding을 넓힘
            margin: '0 auto',   // 가운데 정렬
        },
        }}
    >
        <AppBar position="static" 
        sx={{
            paddingLeft:'8px',
            margin:0,                 
            backgroundColor: '#ffffff', 
            elevation: 0, // Remove shadow
            boxShadow: 'none', // Additional reinforcement to remove shadows
            border: 0 }}>
            <Toolbar>
            <IconButton onClick={handleClose} edge="start" color="inherit" aria-label="back" >
                <img src="/assets/icons/back_button.png" alt="back_button" style={{ width: '10px', height: '17px'}} />
            </IconButton>
            <Box sx={{width:26}}/>
            </Toolbar>
        </AppBar>
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} key={postDetail.id}>
            <PostCard post={postDetail} />
            </Grid>
        </Grid>
      {/* <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',  // 이 속성을 추가하여 모든 자식 요소를 수직 중앙에 정렬
        // p: '0px 20px'
        }}>

    </Box> */}
    <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',  // 이 속성을 추가하여 모든 자식 요소를 수직 중앙에 정렬
        p: '0px 20px',
        pt: '24px'
    }}>
        <Box sx={{ pt:'24px'}}>
            <Typography sx={{
                fontSize: '18px',
                fontWeight: 700,
                color: 'rgba(0, 0, 0, 1)'
            }}>
                지금 인기있어요🔥
            </Typography>
        </Box>
    </Box>
    <Box sx={{ pt:'16px'}}>
        {posts
        .filter(post => post.id !== postDetail.id)
        .map(post => (
            <Grid item xs={12} sm={12} md={12} lg={12} key={post.id}>
              <PostCard post={post} />
            </Grid>
          ))}
          {isLoading && <Typography component="div">Loading more posts...</Typography>}
    </Box>
    </Box>
  );
}

export default PostDetailPage;

