import React,{useState, useEffect, useCallback} from 'react'
import { Box, AppBar, Toolbar, IconButton, TextField, Typography, Grid, Chip,Stack, Tabs, Tab } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import {fetchSearchKeyword, fetchRankingKeyword, fetchMoreSearchKeyword} from '../../repository/search/searchRepository';
import PostCard from '../../common/components/PostCard';
import BottomNav from '../../common/components/BottomNav';

const SearchPage = () => {
    return <Box>
    <SearchContent/>
    <Toolbar/>
    <BottomNav/>
    </Box>
}

const SearchContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const ranking = useSelector((state) => state.search.ranking);

    const [currentTab, setCurrentTab] = useState('recommend'); // 'recommend', 'question', 'tag'
    const [isResult, setIsResult] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
        fetchRankingKeyword(dispatch).then(()=>{
            console.log('rankg',ranking);
        });
    },[]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const keyword = params.get('keyword');
        if (keyword) {
            setQuery(keyword);
            handleSearch(keyword);
        }
    }, [location.search]);

    const handleClose = () => {
        navigate('/main');
    }
    const handleClick = () => {
        console.info('You clicked the Chip.');
      };
    
    const handleDelete = () => {
    console.info('You clicked the delete icon.');
    };

    const handleSearch = (searchQuery = query) => {
        if (searchQuery.trim() !== '') {
            fetchSearchKeyword(dispatch, searchQuery);
            setIsResult(true);
            navigate(`/search?keyword=${searchQuery}`); // Update URL
        }
    };

    return (
    <Box>
        <AppBar position="static" 
        sx={{
            paddingLeft:'8px',
            margin:0,                 
            backgroundColor: '#ffffff', 
            elevation: 0, // Remove shadow
            boxShadow: 'none', // Additional reinforcement to remove shadows
            border: 0 }}>
            <Toolbar>
            <IconButton onClick={handleClose} edge="start" color="inherit" aria-label="back" >
                <img src="/assets/icons/back_button.png" alt="back_button" style={{ width: '10px', height: '17px'}} />
            </IconButton>
            <Box sx={{width:26}}/>
            <TextField
                placeholder="무엇이 궁금하세요?"
                value={query}
                onChange={e => setQuery(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearch();
                    }
                }}
                sx={{
                    width: '100%', // 필요하면 너비 조정
                    '.MuiInputBase-root': {
                    height: '44px',
                    borderRadius: '12px',
                    backgroundColor: 'rgba(245, 247, 248, 1)'
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                    border: 'none' // 외곽선 제거
                    },
                    '.MuiInputBase-input': {
                    height: '100%',
                    padding: '0 14px' // 좌우 패딩 조정 (아이콘 공간 등 고려)
                    }
                }}
                InputProps={{
                    endAdornment: (
                    <IconButton color="inherit" onClick={()=>handleSearch()}>
                        <img src="/assets/icons/search.png" alt="search" style={{ width: '24px', height: '24px'}} />
                    </IconButton>
                    )
                }}/>
            </Toolbar>
        </AppBar>
        {isResult ? <SearchResult currentTab={currentTab} setCurrentTab={setCurrentTab}/> : <PopularityRanking ranking={ranking} handleClick={handleClick} handleDelete={handleDelete}/>}
    </Box>
    )
}
const PopularityRanking = ({ranking, handleClick, handleDelete}) => {
    const navigate = useNavigate();
    return <>
        <Box sx={{
            p: '20px 16px',
            display: 'flex',
            flexDirection: 'row',  // 이 방향을 명확히 지정
            overflowX: 'auto',  // 스크롤 활성화
            flexWrap: 'nowrap',  // 자식 요소가 줄바꿈 없이 나열
            whiteSpace: 'nowrap',  // 공백에 의한 자동 줄바꿈 방지
            '&::-webkit-scrollbar': {
                display: 'none'  // 스크롤바 숨기기 (필요에 따라)
            }
        }}>
                {/* <Chip
                    label="카페 추천"
                    onClick={handleClick}
                    onDelete={handleDelete}
                    sx={{backgroundColor:'rgba(247, 250, 255, 1)', borderRadius:'8px', height:'36px', marginRight:'8px'}}
                    deleteIcon={<CloseIcon sx={{width:'12px', height:'12px', color:'rgba(0,0,0,0.9)'}}/>}
                />
                <Chip
                    label="그리델발트"
                    onClick={handleClick}
                    onDelete={handleDelete}
                    sx={{backgroundColor:'rgba(247, 250, 255, 1)', borderRadius:'8px', height:'36px', marginRight:'8px'}}
                    deleteIcon={<CloseIcon sx={{width:'12px', height:'12px', color:'rgba(0,0,0,0.9)'}}/>}

                />
                <Chip
                    label="융프라우"
                    onClick={handleClick}
                    onDelete={handleDelete}
                    sx={{backgroundColor:'rgba(247, 250, 255, 1)', borderRadius:'8px', height:'36px', marginRight:'8px'}}
                    deleteIcon={<CloseIcon sx={{width:'12px', height:'12px', color:'rgba(0,0,0,0.9)'}}/>}
                />
                <Chip
                    label="융프라우1234"
                    onClick={handleClick}
                    onDelete={handleDelete}
                    sx={{backgroundColor:'rgba(247, 250, 255, 1)', borderRadius:'8px', height:'36px', marginRight:'8px'}}
                    deleteIcon={<CloseIcon sx={{width:'12px', height:'12px', color:'rgba(0,0,0,0.9)'}}/>}
                />
                <Chip
                    label="융프라우2312415"
                    onClick={handleClick}
                    onDelete={handleDelete}
                    sx={{backgroundColor:'rgba(247, 250, 255, 1)', borderRadius:'8px', height:'36px', marginRight:'8px'}}
                    deleteIcon={<CloseIcon sx={{width:'12px', height:'12px', color:'rgba(0,0,0,0.9)'}}/>}
                /> */}
        </Box>
    <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',  // 이 속성을 추가하여 모든 자식 요소를 수직 중앙에 정렬
        p: '0px 20px'
    }}>
        <Box>
            <Typography sx={{
                fontSize: '18px',
                fontWeight: 700,
                color: 'rgba(0, 0, 0, 1)'
            }}>
                지금 인기있어요🔥
            </Typography>
        </Box>
        <Box>
            <Typography sx={{
                fontSize: '12px',
                fontWeight: 500,
                color: 'rgba(115, 115, 115, 1)'
            }}>
                최근 일주일
            </Typography>
        </Box>
    </Box>
    <Box sx={{ pt:'24px'}}>
        {ranking.map((rank) => (
        <Box sx={{
            display:'flex', 
            pl:'40px',
            pb:'18px',
            cursor: 'pointer',
            transition: 'color 0.3s ease, transform 0.2s',
            '&:hover': {
                color: 'rgba(0, 0, 0, 0.7)', // Change text color on hover
                transform: 'scale(1.02)', // Slightly scale up on hover
            },}} onClick={()=> navigate(`/search?keyword=${rank.keyword}`)}>
            <Typography sx={{fontSize:'16px', fontWeight:500, color:'rgba(23, 23, 23, 1)',p:'0px 4px'}}>
                    {rank.rank}
            </Typography>
            <Box sx={{width:'12px'}}/>
            <Typography sx={{fontSize:'16px', fontWeight:500, color:'rgba(23, 23, 23, 1)'}}>
                {rank.keyword}
            </Typography>
        </Box>
        ))}
    </Box>
    </>
}

const SearchResult = ({currentTab, setCurrentTab}) => {
    const dispatch = useDispatch();

    const posts = useSelector((state) => state.search.posts);
    const next = useSelector((state) => state.search.next);
    const count = useSelector((state)=> state.search.count);
    const [isLoading, setIsLoading] = useState(false);

    const fetchMorePosts = useCallback(async () => {
        if (isLoading || next === null) return; // Stop if loading or no more pages
    
        try {
           fetchMoreSearchKeyword(dispatch, next);
        } catch (error) {
          console.error('Failed to load more posts:', error);
        } finally {
          setIsLoading(false);
        }
      }, [isLoading, next, dispatch]);
      
      useEffect(() => {
        const handleScroll = () => {
          if (window.innerHeight + document.documentElement.scrollTop + 5 >= document.documentElement.offsetHeight) {
            fetchMorePosts();
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [fetchMorePosts]);

    return <Box sx={{borderBottom: 1, borderColor: 'divider'}} >
    {/* <Tabs 
    value={currentTab} 
    onChange={(event, newValue) => setCurrentTab(newValue)} 
    aria-label="simple tabs example"
    // indicatorColor="primary"
    // textColor="primary"
    variant="fullWidth"
    sx={{
      '.MuiTabs-indicator': { // 활성화 시 아래 밑줄
        backgroundColor: 'black', // Sets the indicator (the underline) to black
        height: '2.5px', // Increases the height to make it thicker
      },
      '.Mui-selected': {  // 선택된 탭에 대한 스타일
        color: 'rgba(0,0,0,0.9)'
      },
      '.MuiTab-root': {
        // color: '#000000', // Sets the text color of all tabs to black
        fontSize: '16px', // Sets the font size of all tabs to 16px
        fontWeight: 600, // Sets the font weight of all tabs to 600
      },
      '.MuiTab-root.Mui-disabled': {
        // 비활성화된 탭 스타일
        color: '#c4c4c4', // 비활성화된 탭의 글자 색
        '& .MuiTabs-indicator': {
          backgroundColor: 'gray', // 비활성화된 탭의 밑줄 색
          height: '2px'
        },
      }
    }}
  >
    <Tab label="추천" value="recommend" />
    <Tab label="질문" value="question" />
    <Tab label="태그" value="tag"/>
  </Tabs> */}
    <Grid container >
    <Box sx={{p: '0px 20px'}}>
        <Typography sx={{
                fontSize: '14px',
                fontWeight: 700,
                color: 'rgba(0, 0, 0, 1)'
            }}>
            검색 결과 : {count}
        </Typography>
    </Box>
    {posts.map(post => (
        <Grid item xs={12} sm={12} md={12} lg={12} key={`search-${post.id}`}>
        <PostCard post={post} />
        </Grid>
    ))}
    {isLoading && <Typography component="div">Loading more posts...</Typography>}
</Grid>
  </Box>
}

export default SearchPage;