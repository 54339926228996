import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Avatar, TextField, Divider, Typography, Box, IconButton } from '@mui/material';
import Puller from '../../common/components/Puller';
import { icons } from '../../constants/icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetComments, fetchPostComments } from '../../repository/comments/commentsRepository';
import { formatTime } from '../../util/utils';
import { styled } from '@mui/material/styles';
import CustomSnackbar from '../../common/components/exception/CustomSnackbar';

const HighlightedText = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
  padding: '12px 14px', // TextField padding과 맞춤
  zIndex: 1,
  overflow: 'auto',
  fontSize: '14px', // TextField와 동일한 폰트 크기
  fontWeight: 400, // TextField와 동일한 폰트 굵기
  lineHeight: '20px', // TextField와 동일한 줄 높이
  '.highlight': {
    color: 'blue',
  },
});

const CommentDrawer = ({ postGroupId, open, handleClose }) => {
  const dispatch = useDispatch();
  const [isSend, setIsSend] = useState(false);
  const [commentText, setCommentText] = useState(''); // 댓글 입력 상태
  const [isReplyTo, setIsReplyTo] = useState(0);
  const [isPosting, setIsPosting] = useState(false); // 요청 중 상태
  const comments = useSelector((state) => state.comments.comments);
  const userId = useSelector((state) => state.user.userId);
  const commentsEndRef = useRef(null);
  const commentInputRef = useRef(null);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    actionIcon: null,
    actionRoute: '',
  });

  useEffect(() => {
    if (open) {
      fetchGetComments(dispatch, postGroupId);
    }
  }, [open, postGroupId, dispatch]);

  useEffect(() => {
    setIsSend(commentText.length > 0);
  }, [commentText]);


  const handleCloseSnackbar = () => {
    setSnackbarState(prevState => ({ ...prevState, open: false }));
  };

  const handleInputChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleReplyTo = (commentInfo) => {
    console.log('handleReplyTo', commentInfo)
    setCommentText(`@${commentInfo.user.username} `)
    setIsReplyTo(commentInfo.id)
    // TextField에 포커스 설정
    commentInputRef.current.focus();
  }


  const handleSendClick = () => {
    // 아이디가 존재하지않을 경우 예외처리
    if (!userId) {
      setSnackbarState({
        open: true,
        message: "로그인이 필요해요. 👉🏻",
        actionIcon: "/assets/icons/login_icon.png",
        actionRoute: "/login"
      });
      return;
    }

    if (isSend && !isPosting) {
      setIsPosting(true);

      //   const contentState = editorState.getCurrentContent();
      //   const contentRaw = JSON.stringify(convertToRaw(contentState));
      const bodyParam = isReplyTo > 0 ? {
        is_reply_to: isReplyTo,
        content: commentText,
      } : {
        content: commentText,
      };
      fetchPostComments(dispatch, postGroupId, bodyParam).then(() => {
        // setEditorState(EditorState.push(editorState, ContentState.createFromText('')));
        setCommentText('');
        setIsPosting(false);
        setIsReplyTo(0);
        scrollToBottom();
      }).catch(() => {
        setIsPosting(false); // 실패 시에도 isPosting 상태를 해제합니다.
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (isSend) {
        event.preventDefault();
        handleSendClick();
      }
    }
  };

  const getHighlightedText = (text) => {
    const parts = text.split(/(@\S+)/g);
    return parts.map((part, index) => (
      part.match(/(@\S+)/g) ? <span key={index} className="highlight">{part}</span> : part
    ));
  };

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 32, // Top left border radius
          borderTopRightRadius: 32, // Top right border radius
          borderColor: '#FFFFFF',
          zIndex: 1300,
          backgroundColor: '#FFFFFF', // Background color of the drawer
          height: 'auto', // Adjust this value as needed to cover the bottom nav
          minHeight: '267px',
          overflow: 'visible'
        }
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Puller />
        <Box sx={{ height: 16 }} />
        <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>댓글</Typography>
        </Box>
        {comments.length > 0 ? <Box sx={{ width: '100%', minHeight: '123px', maxHeight: 'calc(100vh - 267px)', overflowY: 'auto', overflowX: 'hidden', mt: 2 }}>
          <CommentList comments={comments} commentsEndRef={commentsEndRef} handleReplyTo={handleReplyTo} snackbarState={snackbarState} handleCloseSnackbar={handleCloseSnackbar}/>
        </Box>
          : <Box sx={{ width: '100%', height: '123px', }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.35)', textAlign: 'center', pt: '50px' }}>
              첫 댓글을 남겨보세요!
            </Typography>
          </Box>
        }
        <Box display="flex" justifyContent="center" sx={{ width: '90%', pt: '20px', pb: '16px' }}>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TextField
              value={commentText}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              fullWidth
              multiline
              maxRows={2}
              placeholder="나의 생각을 댓글로 남겨주세요"
              inputRef={commentInputRef}
              sx={{
                '.MuiInputBase-root': {
                  height: 'auto',
                  borderRadius: '12px',
                  backgroundColor: 'rgba(245, 247, 248, 1)',
                  padding: '0 14px', // 좌우 패딩 조정 (아이콘 공간 등 고려)
                  minHeight: '44px',
                  maxHeight: '88px',
                  overflow: 'auto', // 스크롤을 가능하게 함
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none' // 외곽선 제거
                },
                '.MuiInputBase-input': {
                  padding: '12px 0', // 패딩 조정
                  boxSizing: 'border-box', // 높이 계산을 위한 박스 모델 설정
                  position: 'relative',
                  zIndex: 2,
                  color: 'transparent', // 입력 텍스트 투명하게 설정
                  textShadow: 'none', // 텍스트 그림자 제거
                  caretColor: 'black', // 포커스 커서 색상 설정
                  fontSize: '14px', // TextField와 동일한 폰트 크기
                  fontWeight: 400, // TextField와 동일한 폰트 굵기
                  lineHeight: '20px', // TextField와 동일한 줄 높이
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton color="inherit" onClick={handleSendClick} disabled={!isSend}>
                    <img src={isSend ? icons.send.active : icons.send.inactive} alt="send" style={{ width: '24px', height: '24px' }} />
                  </IconButton>
                )
              }}
            />
            <HighlightedText aria-hidden="true">
              {getHighlightedText(commentText)}
            </HighlightedText>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

const CommentList = ({ comments, commentsEndRef, handleReplyTo, snackbarState, handleCloseSnackbar}) => {
  // const [activeCommentIndex, setActiveCommentIndex] = useState(null); // 현재 활성화된 comment 버튼의 인덱스
  console.log('comment test', comments)
  // const handleCommentClick = (index) => {
  //   console.log('activeCommentIndex', index)
  //   if (activeCommentIndex === index) {
  //     setActiveCommentIndex(null); // 같은 버튼을 다시 클릭하면 비활성화
  //   } else {
  //     setActiveCommentIndex(index); // 클릭된 버튼의 인덱스를 활성화
  //   }
  // };

  return (
    <>
      {comments.map((comment, index) => (
        <Box key={`comment-card-${index}`} ref={commentsEndRef} sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
          <Box sx={{ width: '100%', pb: '0px' }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between', // This ensures that the items are spread across the container
              p: '16px 20px',
            }}>
              <Box sx={{ display: 'flex' }}>
                <Avatar src={comment.user.profile_image_url} sx={{ width: 28, height: 28, marginRight: '8px' }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)', alignSelf: 'flex-start', pr: '6px' }}>
                      {comment.user.username}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0,0,0,0.65)', alignSelf: 'center' }}>
                      {formatTime(comment.created_at)}
                    </Typography>
                  </Box>
                  <Box sx={{ pt: '4px' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(0,0,0,0.9)' }}>
                      {comment.content}
                    </Typography>
                  </Box>
                  <Box sx={{ pt: '8px', m: 0 }}>
                    <IconButton sx={{ p: 0, mr: '14px' }} >
                      <img src={'/assets/icons/comments/inactive/heart.png'} alt="heart" style={{ width: 20, height: 20 }} />
                    </IconButton>
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => handleReplyTo(comment)}
                    >
                      <img
                        src={'/assets/icons/comments/comment.png'}
                        alt="comment"
                        style={{ width: 20, height: 20 }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box>
                <IconButton sx={{ p: 0, m: 0 }}>
                  <img src={'/assets/icons/comments/more.png'} alt="more" style={{ width: 20, height: 20 }} />
                </IconButton>
              </Box>
            </Box>
            <Divider variant="middle" />
            {comment.replies && <ReplyList key={comment.id} replies={comment.replies} />}
          </Box>
          <CustomSnackbar
            open={snackbarState.open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={snackbarState.message}
            actionIcon={snackbarState.actionIcon}
            actionRoute={snackbarState.actionRoute}
          />
        </Box>
      ))}
    </>
  );
};

const ReplyList = ({ replies }) => {
  return <> {replies.map((reply) => (
    <Box sx={{ width: '100%', pb: '0px' }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between', // This ensures that the items are spread across the container
        p: '16px 20px 16px 45px',
      }}>
        <Box sx={{ display: 'flex' }}>
          <Avatar src={reply.user.profile_image_url} sx={{ width: 28, height: 28, marginRight: '8px' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)', alignSelf: 'flex-start', pr: '6px' }}>
                {reply.user.username}
              </Typography>
              <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0,0,0,0.65)', alignSelf: 'center' }}>
                {formatTime(reply.created_at)}
              </Typography>
            </Box>
            <Box sx={{ pt: '4px' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(0,0,0,0.9)' }}>
                {reply.content}
              </Typography>
            </Box>
            <Box sx={{ pt: '8px', m: 0 }}>
              <IconButton sx={{ p: 0, mr: '14px' }} >
                <img src={'/assets/icons/comments/inactive/heart.png'} alt="heart" style={{ width: 20, height: 20 }} />
              </IconButton>
              <IconButton sx={{ p: 0 }}>
                <img src={'/assets/icons/comments/comment.png'} alt="comment" style={{ width: 20, height: 20 }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box>
          <IconButton sx={{ p: 0, m: 0 }}>
            <img src={'/assets/icons/comments/more.png'} alt="more" style={{ width: 20, height: 20 }} />
          </IconButton>
        </Box>
      </Box>
      <Divider variant="middle" />
    </Box>
  ))} </>
}
export default CommentDrawer;