import React,{useEffect} from 'react';
import { Toolbar, Box } from '@mui/material';
import BottomNav from './common/components/BottomNav';
import Header from './common/components/Header';
import MainTabs from './common/components/MainTabs';
import MenuBar from './features/main/MenuBar';
import CarouselBanner from './features/main/CarouselBanner';
import { useDispatch } from 'react-redux';
import api from './api/apiClient';
import { fetchFeedList } from './repository/posts/postsRepository';

function MainPage() {
  const dispatch = useDispatch();

  // Redux 스토어에서 username을 가져옵니다.
  useEffect(() => {
    // 비동기 함수 호출
    fetchFeedList(dispatch);
    console.log('MainPage rendered');
  }, []);

  return (
    <Box
    sx={{
      width: '100%',
      padding: '0', // 기본 padding
      boxSizing: 'border-box',
      '@media (min-width: 600px)': {
        maxWidth: '600px',  // 최대 너비
        padding: '0',  // 너비가 1200px 이상일 때 padding을 넓힘
        margin: '0 auto',   // 가운데 정렬
      },
    }}
  >
      <Header/>
      {/* AppBar의 높이만큼 패딩을 추가하여 내용이 가려지지 않도록 합니다. */}
      <Toolbar />
      <Box sx={{height:'10px'}}/>
      <CarouselBanner/>
      <Box sx={{height:'10px'}}/>
      {/* <MenuBar/> */}
      <MainTabs/>
      <Toolbar />
      <BottomNav/>
    </Box>
  );
}

export default MainPage;
