import { getUserInfo } from '../../api/SwissApi';
import { setUser } from './userSlice';

export const checkLoginStatus = async (dispatch, navigate) => {
    try {
        const response = await getUserInfo();
        const { id :userId, username, profile_image_url: profileImageUrl } = response;
        dispatch(setUser({ userId, username, profileImageUrl }));
    } catch (error) {
        console.error('Failed to checkLoginStatus:', error);
        if (navigate) {
            navigate('/login');
        }
    }
}