import React, { useState } from 'react';
import { 
  Box, 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  TextField,
  Avatar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const EditProfilePage = () => {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState(null);
  const user = useSelector((state) => state.profiles.user);

  return (
    <Box sx={{
      '@media (min-width: 600px)': {
        maxWidth: '600px',
        margin: '0 auto',
      }
    }}>
      <AppBar 
        position="static" 
        color="transparent" 
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton 
            edge="start" 
            onClick={() => navigate(-1)}
          >
            <img 
              src="/assets/icons/back_button.png" 
              alt="뒤로가기" 
            //   style={{ width: '24px', height: '24px' }} 
            />
          </IconButton>
          <Typography sx={{ 
            color: 'rgba(58, 139, 255, 1)',
            fontSize: '15px',
            fontWeight: 600,
            cursor: 'pointer'
          }}>
            완료
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          mb: 4
        }}>
          <Box sx={{ position: 'relative' }}>
            <Avatar 
              sx={{ 
                width: 100, 
                height: 100,
                mb: 1
              }}
              src={user.profile_image_url}
            />
            <IconButton 
              sx={{ 
                position: 'absolute',
                right: -10,
                bottom: 0,
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                padding: '4px'
              }}
            >
              <img 
                src="/assets/icons/camera.png" 
                alt="이미지 수정" 
                style={{ width: '20px', height: '20px' }} 
              />
            </IconButton>
          </Box>
          <Typography sx={{ 
            color: 'rgba(58, 139, 255, 1)',
            fontSize: '14px',
            mt: 1
          }}>
            이미지 선택
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography sx={{ 
            fontSize: '13px', 
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 1
          }}>
            닉네임
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={user.username}
            placeholder="닉네임을 입력해주세요"
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography sx={{ 
            fontSize: '13px', 
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 1
          }}>
            이메일
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="이메일을 입력해주세요"
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
        </Box>

        <Box>
          <Typography sx={{ 
            fontSize: '13px', 
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 1
          }}>
            소개
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="나를 소개해주세요!"
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EditProfilePage;