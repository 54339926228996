import { setComments, addComments } from './commentsSlice';
import { getComments, postComments } from '../../api/SwissApi';
import { addCommentByPostGroupId } from '../posts/postsSlice';
export const fetchPostComments = async (dispatch, postGroupId, bodyParam) => {
    try {
        const comment = await postComments(postGroupId, bodyParam);
        dispatch(addComments(comment));
        dispatch(addCommentByPostGroupId(postGroupId));
        // post comment_count 증가
    } catch (error) {
        console.error('Failed to fetch bookmark lists:', error);
    }
};

export const fetchGetComments = async (dispatch, postGroupId) => {
    try {
        const comments = await getComments(postGroupId); 
        dispatch(setComments(comments));
    } catch (error) {
        console.error('Failed to fetch comments:', error);
    }
}