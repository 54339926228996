import React from 'react';
import { IconButton } from '@mui/material';

function GoogleLoginButton() {
    const handleLogin = () => {
      const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
      const options = {
        redirect_uri: `${process.env.REACT_APP_GOOGLE_REDIRECT_URI}`,
        client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`, 
        access_type: 'online',
        response_type: 'code',
        scope: 'email profile openid'
      };
      const qs = new URLSearchParams(options);
      window.location.href = `${rootUrl}?${qs.toString()}`;
    };

    return (
      <IconButton
        onClick={handleLogin}
        variant="contained"
        sx={{ backgroundColor: 'rgba(255, 255, 255, 1)', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
        width: '58px', height:'58px'}}
      >
        <img src='/assets/google_logo.png' alt="Google" style={{ width: '22px',height:'22px' }} />
      </IconButton>
    );
}

export default GoogleLoginButton;
