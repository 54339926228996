import React,{useState, useEffect} from 'react';
import { Box, Card, Typography, IconButton } from '@mui/material';
import { icons } from '../../constants/icons';
import {postBookmarks, deleteBookmarks} from '../../api/SwissApi';
import { useSelector } from 'react-redux';

const categorizePlaces = (types) => {
    if (types.includes('cafe')) {
      return '/assets/icons/place_cafe.png';
    } else if (types.includes('restaurant')) {
      return '/assets/icons/place_restaurant.png';
    } else if (types.includes('lodging')) {
      return '/assets/icons/place_hotel.png';
    } 
    return '/assets/icons/place_default.png';
  }

const LocationCard = ({location, handleClose, isLocationBookmarked, readOnly, setSnackbarState, handleLoginAlert }) => {
    const [isPlaceBookmark, setIsPlaceBookmark] = useState(false);
    const userId = useSelector((state) => state.user.userId);

    useEffect(() => {
        setIsPlaceBookmark(isLocationBookmarked);
    },[isLocationBookmarked]);

    const handlePlaceBookmark = (location) => {
      if (!userId) {
        handleLoginAlert();
        return;
      }
      setIsPlaceBookmark(!isPlaceBookmark);
        if(!isPlaceBookmark){
          setSnackbarState({
            open: true,
            message: "내장소에 추가했어요!",
            actionIcon: "assets/icons/white_right_arrow.png",
            actionRoute: "/bookmarks?type=location"
          });
          postBookmarks('location', location.id).then(res => {
            console.log(res.status)
          })
        } else {
          setSnackbarState({
            open: true,
            message: "내장소에서 삭제했어요!",
            actionIcon: null,
            actionRoute: ""
          });
          deleteBookmarks('location', location.id).then(res => {
            console.log(res.status)
          })
        }
    
    }
    return (
      <Card sx={{ 
        boxShadow: 'none',
        p:0,
        margin:0, 
        border: 0,    
        '&:hover': {
          borderRadius: '12px', // Increase border radius on hover
        }
      }}> 
        <Box sx={{
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          height: '64px', 
          pl: '12px',
          pr: '12px', 
          margin: 0,
          backgroundColor: 'rgba(245, 247, 248, 1)', 
          borderRadius: '12px'
        }}>
          <Box sx={{ display: 'flex' }}>  
            <img src={categorizePlaces(location.types)} alt="category icon" style={{ width: 40, height: 40 }}/>
            <Box sx={{pl:'10px'}}> 
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(38, 38, 38, 1)' }}>
                {location.name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>  
                <img src='/assets/icons/location-14.png' alt='location' style={{ width: 14, height: 14 }}/>
                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(38, 38, 38, 1)' }}>
                  {location.address}
                </Typography>
              </Box>
            </Box>
          </Box>
          {readOnly ? 
                <IconButton edge="start" onClick={()=>{handlePlaceBookmark(location)}} aria-label="placeBookmark" sx={{ width:'34px', height:'34px', color: 'black', backgroundColor:'rgba(255,255,255,1)' }}>
                <img src={isPlaceBookmark ? icons.placeBookmark.active : icons.placeBookmark.inactive} alt='bookmark' style={{ width: 22, height: 22}}/>
                </IconButton>
          :
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ color: 'black' }}>
            <img src='/assets/icons/close.png' alt='close' style={{ width: 22, height: 22}}/>
          </IconButton>
          
          }
          </Box>
      </Card>
    );
  };

  export default LocationCard;