import React from 'react';
import { Toolbar, Box, Grid, Paper, Typography, TextField, IconButton, Button,List,ListItem,ListItemText,Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BottomNav from './common/components/BottomNav';
import CarouselComponent from './CarouselComponent';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Header from './common/components/Header';

const questionItems = [
  {
    title: '동식물을 좋아한다면 이 곳! 스위스 동물원',
    description: '최근에 오픈한 스위스의 새로운 동물원을 소개합니다!',
  },
  {
    title: 'Bodenwald 숲에서의 힐링',
    description: 'Bodenwald의 신비로운 숲길을 걸어보세요.',
  },
  // 추가 질문...
];


function HomePage() {
  // Redux 스토어에서 username을 가져옵니다.

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Header/>
      {/* AppBar의 높이만큼 패딩을 추가하여 내용이 가려지지 않도록 합니다. */}
      <Toolbar />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper component="form" sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TextField
              fullWidth
              placeholder="여행지를 검색하세요"
              InputProps={{
                endAdornment: (
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Paper>
        </Grid>
        
        <Grid item xs={12} sx={{ paddingTop: 4, paddingBottom: 4 }}>
          <CarouselComponent />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginBottom: 2 }}>
          <Typography variant="h5">인기글</Typography>
          <Button variant="text">더보기</Button>
        </Grid>
        <Grid item container spacing={2}>
          {/* 여행 정보 카드들 */}
          <Grid item xs={12} sm={6} md={4}>
            <CardItem title="인생 여행지가 될 스위스" description="닉네임" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardItem title="5월 스위스 가볼 만한 곳" description="닉네임" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardItem title="루체른 숙소 후기 Kobi..." description="닉네임" />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginTop:4, marginBottom: 2 }}>
          <Typography variant="h5">추천글</Typography>
        </Grid>
        <Grid item container spacing={2}>
          {/* 여행 정보 카드들 */}
          <Grid item xs={12} sm={6} md={4}>
            <CardItem title="인생 여행지가 될 스위스" description="닉네임" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardItem title="5월 스위스 가볼 만한 곳" description="닉네임" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardItem title="루체른 숙소 후기 Kobi..." description="닉네임" />
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          실시간 질문답변
        </Typography>
        <Paper elevation={0} variant="outlined" sx={{ maxHeight: 400, overflow: 'auto' }}>
          <List>
            {questionItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={item.title}
                    secondary={item.description}
                  />
                </ListItem>
                {index < questionItems.length - 1 && <Divider variant="inset" component="li" />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
        <Button size="small" endIcon={<ArrowForwardIosIcon />} sx={{ mt: 1 }}>
          더보기
        </Button>
      </Box>
      <Toolbar />
      <BottomNav/>
    </Box>
  );
}

// 각 여행 정보를 보여주는 카드 컴포넌트
function CardItem({ title, description }) {
    return (
      <Paper elevation={2} sx={{ padding: 2 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Paper>
    );
  }

export default HomePage;
