import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const CarouselComponent = () => {
  return (
    <>
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >  
      <SwiperSlide><img src="https://source.unsplash.com/random/800x600?swiss-alps" alt="Swiss Alps" /></SwiperSlide>
      <SwiperSlide><img src="https://source.unsplash.com/random/800x600?zurich" alt="Zurich" /></SwiperSlide>
      <SwiperSlide><img src="https://source.unsplash.com/random/800x600?geneva" alt="Geneva" /></SwiperSlide>
      <SwiperSlide><img src="https://source.unsplash.com/random/800x600?lucerne" alt="Lucerne" /></SwiperSlide>
      <SwiperSlide><img src="https://source.unsplash.com/random/800x600?bern" alt="Bern" /></SwiperSlide>
      <SwiperSlide><img src="https://source.unsplash.com/random/800x600?interlaken" alt="Interlaken" /></SwiperSlide>
      <SwiperSlide><img src="https://source.unsplash.com/random/800x600?matterhorn" alt="Matterhorn" /></SwiperSlide>
      <SwiperSlide><img src="https://source.unsplash.com/random/800x600?lausanne" alt="Lausanne" /></SwiperSlide>
    </Swiper>
    </>
  );
};

export default CarouselComponent;