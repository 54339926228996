import React from 'react';
import GoogleLoginButton from './GoogleLoginButton';
import { AppBar, Toolbar,IconButton, Typography, Box,Divider } from '@mui/material';
import KakaoLoginButton from './KakaoLoginButton';
import NaverLoginButton from './NaverLoginButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';


function LoginPage() {
    const navigate = useNavigate();

    const handleClose = () => {
        navigate("/main");
    }
    return (
        <Box>
            <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                backgroundImage: 'url(/assets/images/bg_login2.webp)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor:'rgba(255,255,255,0.5)',
                zIndex: -1
            }}/>
            <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(255, 255, 255, 0.6)', // 하얀색 불투명 오버레이
                zIndex: 0
            }}/>
            <AppBar position="static" color="transparent" sx={{
                position: 'absolute',
                p:0,
                pl:'20px',
                margin:0,
                boxShadow: 'none', // Additional reinforcement to remove shadows
                border: 0, // Ensure no borders
                zIndex: 5
            }}>
                <Toolbar sx={{ justifyContent: 'space-between',p:0, margin:0}}>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ color: 'black' }}>
                    <CloseIcon />
                </IconButton>
                {/* <Button color="inherit" onClick={handleSubmit} sx={{ color: '#000000', fontSize:'16px',fontWeight:600 }}>
                    완료
                </Button> */}
                </Toolbar>
            </AppBar>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    zIndex: 2
                }}
            >
                <Box sx={{paddingTop:'80px',display: 'flex',flexDirection: 'column',justifyContent: 'center', alignItems:'center'}}>
                    <Typography sx={{fontSize:'26px', fontWeight:700, color:'rgba(0,0,0,0.9)'}}>
                        로그인하고
                    </Typography>
                    <Typography sx={{fontSize:'26px', fontWeight:700, color:'rgba(0,0,0,0.9)'}}>
                        {/* 진짜 스위스를 만나보세요! */}
                        예술을 함께 즐겨보세요!
                    </Typography>
                    <Box sx={{height:20}}/>
                    <img src='/assets/Logo.png' alt='logo' style={{width: '130px', height: '20px'}}/>
                </Box>
                <Box sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center', alignItems:'center'}}>
                    <Box sx={{width:'332px'}}>
                        <KakaoLoginButton />
                    </Box>    
                    <Box sx={{height:'17px', width : '100%', border:0}}>
                    <Divider light={true}>
                        <Typography color="inherit" sx={{ 
                            fontSize: '12px', 
                            fontWeight: 400, 
                            color:'rgba(0, 0, 0, 0.4)',
                        }}>
                        또는
                        </Typography>
                    </Divider>
                    </Box>
                    <Box sx={{height:'10px'}}/>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                        <GoogleLoginButton />
                        <Box sx={{width:'10px'}}/>
                        <NaverLoginButton />
                    </Box>
                    <Box sx={{height:'20px'}}/>
                    <Box sx={{textAlign:'center', paddingBottom:'40px'}}>
                        <Typography sx={{fontSize:'12px', fontWeight:400, color:'rgba(0,0,0,0.9)'}}>
                            회원가입 시 이용자는 "아트프렌즈" 이용약관 및
                        </Typography>
                        <Typography sx={{fontSize:'12px', fontWeight:400, color:'rgba(0,0,0,0.9)'}}>
                        개인정보처리방침에 동의하게 됩니다.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default LoginPage;

// <Box>
//             <Box sx={{
//             position: 'fixed', // 화면에 고정
//               top: 0,
//               left: 0,
//               width: '100%',
//               height: '194px',
//               backgroundImage: 'url(/assets/images/bg_login.png)',
//               backgroundSize: 'cover',
//               backgroundPosition: 'center',
//               backgroundRepeat: 'no-repeat',
//               zIndex: -1 // 다른 요소들 뒤에 위치
//             }}/>
//             <Box
//                 sx={{
//                     marginTop: 8,
//                     display: 'flex',
//                     flexDirection: 'column',
//                     alignItems: 'center',
//                 }}
//             >
//                 <Typography sx={{fontSize:'26px', fontWeight:700, color:'rgba(0,0,0,0.9)'}}>
//                     로그인하고
//                 </Typography>
//                 <Typography sx={{fontSize:'26px', fontWeight:700, color:'rgba(0,0,0,0.9)'}}>
//                     진짜 스위스를 만나보세요!
//                 </Typography>
//                 <Box sx={{height:20}}/>
//                 <img src='/assets/Logo.png' alt='logo' style={{width: '130px', height: '20px'}}/>
//                 <Box sx={{height:332}}/>
//                 <Grid container spacing={2} justifyContent="center">
//                     <GoogleLoginButton />
//                 </Grid>
//                 <Grid container spacing={2} justifyContent="center" >
//                     <KakaoLoginButton/>
//                 </Grid>
//                 <Grid container spacing={2} justifyContent="center">
//                     <NaverLoginButton/>
//                 </Grid>

//             </Box>
//         </Box>