import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../api/apiClient';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography } from '@mui/material'; // Box와 Typography 추가
import { useNavigate, useSearchParams } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';
import { useDispatch } from 'react-redux';
import { updateEditorAtIndex } from '../../repository/posts/postsSlice';

function PlaceSearchGoogle() {
  const [query, setQuery] = useState('');
  const [places, setPlaces] = useState([]);
  const [searchParams] = useSearchParams();
  const contentIndex = searchParams.get('content_index');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (query.length > 0) { // 최소 글자 이상 입력 시 API 호출
      const response = api.get('/api/search/google/autocomplete', {
          params: { input: query },
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          }
      }).then(response => {
        setPlaces(response.data.predictions); // predictions 배열에 결과 저장
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  }, [query]);

  const handleClose = () => {
    navigate(-1);
  };
  const AddLocation = (placeId) => {
    api.get('/api/search/google/places/details', {
      params: { place_id: placeId },
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
    .then(response => {
      if (response.status === 200 ) {
        const result = response.data.result;
        console.log(result);
        dispatch(updateEditorAtIndex({
          index: contentIndex || 0, 
          location: {
            name: result.name,
            address: result.formatted_address,
            types: result.types,
            latitude: result.geometry.location.lat,
            longitude: result.geometry.location.lng,
          },
        }));
      }
    })
    .catch(error => {
      console.error('Error fetching places/details:', error);
    }).finally(() => {
      navigate(`/posts/contents/edit?content_index=${contentIndex}`);
    });    
  }

  const handleMapOpen = (event, placeInfo) => {
    event.stopPropagation();  // 이벤트 버블링 중단
    // 여기에 지도를 열기 위한 로직을 구현할 수 있습니다.
    navigate(`/search/places/google/map?place_id=${placeInfo.place_id}`);
  };
  return (
    <Box>
    <AppBar position="static"
      sx={{
        paddingLeft: '8px',
        margin: 0,
        backgroundColor: '#ffffff',
        elevation: 0, // Remove shadow
        boxShadow: 'none', // Additional reinforcement to remove shadows
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
      }}>
      <Toolbar sx={{ minHeight: '48px', height: '48px'}}>
        <IconButton onClick={handleClose} edge="start" color="inherit" aria-label="back">
          <img src="/assets/icons/back_button.png" alt="back_button" style={{ width: '10px', height: '17px'}} />
        </IconButton>
        {/* 가운데에 텍스트를 정렬하기 위한 빈 Box를 왼쪽에 추가합니다 */}
        <Box sx={{ flexGrow: 1 }} />
        <Typography sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', fontSize: '16px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.85)' }}>
          구글에서 장소 찾기
        </Typography>
        {/* 오른쪽에 공간을 맞추기 위한 빈 Box */}
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </AppBar>
      <Box display="flex" justifyContent="center" sx={{p:2, pt:'20px',pb:'20px'}}>
        <TextField
              placeholder="장소를 검색하세요"
              value={query}
              onChange={e => setQuery(e.target.value)}
              sx={{
                  width: '100%', // 필요하면 너비 조정
                  '.MuiInputBase-root': {
                  height: '44px',
                  borderRadius: '12px',
                  backgroundColor: 'rgba(245, 247, 248, 1)'
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                  border: 'none' // 외곽선 제거
                  },
                  '.MuiInputBase-input': {
                  height: '100%',
                  padding: '0 14px' // 좌우 패딩 조정 (아이콘 공간 등 고려)
                  }
              }}
              InputProps={{
                  endAdornment: (
                  <IconButton color="inherit">
                      <img src="/assets/icons/search.png" alt="search" style={{ width: '24px', height: '24px'}} />
                  </IconButton>
                  )
              }}/>
      </Box>
      <List component="nav" aria-label="main mailbox folders">
        {places.map(place => (
        <ListItem button key={place.place_id} onClick={() => AddLocation(place.place_id)} >
            <ListItemText primary={
               <React.Fragment>
               <Typography component="span" sx={{ fontSize: '14px', fontWeight:500, color: 'rgba(38, 38, 38, 1)' }}>
                 {place.structured_formatting.main_text}
               </Typography>
              </React.Fragment> 
            } 
            secondary={
              <React.Fragment>
              <img src="/assets/icons/location-14.png" alt="location" style={{ verticalAlign: 'middle', width: '14px', height: '14px', marginRight: '1px' }} />
              <Typography component="span" sx={{ fontSize: '12px', fontWeight:400, color: 'rgba(0, 0, 0, 0.55)' }}>
                {place.structured_formatting.secondary_text}
              </Typography>
            </React.Fragment>
              } />
            <ListItemIcon>
                <IconButton onClick={(event) => handleMapOpen(event, place)} edge="end" aria-label="map">
                  <Typography sx={{fontSize:'14px', fontWeight:500, color:'rgba(0, 0, 0, 0.55)'}}>
                    지도
                  </Typography>
                </IconButton>
            </ListItemIcon>
        </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default PlaceSearchGoogle;