import React from 'react';
import { IconButton } from '@mui/material';

function NaverLoginButton() {
  const NAVER_OAUTH2_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}&state=STATE_STRING`;

  const handleLogin = () => {
    window.location.href = NAVER_OAUTH2_URL;
  };

  return (
    <IconButton
      onClick={handleLogin}
      variant="contained"
      sx={{ backgroundColor: 'rgba(255, 255, 255, 1)', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
      width: '58px', height:'58px'}}
    >
      <img src='/assets/naver_logo.png' alt="Naver" style={{ width: '22px',height:'22px' }} />
    </IconButton>
  );
}

export default NaverLoginButton;