import { getBookmarks } from '../../api/SwissApi';
import { setLocation, setPostGroup } from './bookmarksSlice';

export const fetchBookmarkLists = async (dispatch) => {
    try {
        const [postBookmarks, locationBookmarks] = await Promise.all([
            getBookmarks('post'),
            getBookmarks('location')
        ]);
        dispatch(setPostGroup(postBookmarks));
        dispatch(setLocation(locationBookmarks));
    } catch (error) {
        console.error('Failed to fetch bookmark lists:', error);
    }
};