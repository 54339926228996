import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkLoginStatus } from './repository/user/userRepository';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation(); // URL 경로를 추적하기 위한 훅
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    checkLoginStatus(dispatch, navigate);
  }, [dispatch, navigate, location.pathname]);

  return children;
};

export default ProtectedRoute;