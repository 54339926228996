import { createSlice } from '@reduxjs/toolkit';

export const profilesSlice = createSlice({
  name: 'profiles',
  initialState: {
    posts: [],
    user: {},
    followed_count:0,
    follower_count:0,
    is_follow:false,
    followers:[],
  },
  reducers: {
    setProfile: (state, action) => {
      state.posts = action.payload.posts_group;
      state.user = action.payload.user;
      state.followed_count = action.payload.followed_count;
      state.follower_count = action.payload.follower_count;
      state.is_follow = action.payload.is_follow;
    },
    setFollower: (state, action) => {
      state.followers = action.payload.results;
    },
    addFollower: (state, action) => {
      state.followers = [...state.followers, action.payload];
      state.is_follow = true;
      state.followed_count += 1;
    },
    removeFollower: (state, action) => {
      state.followers = state.followers.filter(follower => follower.user.id != action.payload);
      state.is_follow = false;
      state.followed_count -= 1;
    },
    setIsFollow: (state, action) => {
      state.is_follow = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, setFollower, setIsFollow, addFollower, deleteFollower, removeFollower } = profilesSlice.actions;

export default profilesSlice.reducer;