import React from 'react';
import { Button,Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
  display: 'flex',
  justifyContent: 'flex-start', // 내용을 왼쪽으로 정렬
  alignItems: 'center', // 아이템들을 세로 중앙에 위치
  position: 'relative', // 상대적 위치
  padding: '0px 20px', // 버튼 내부 패딩
  '& .MuiButton-startIcon': {
    position: 'absolute', // 아이콘을 절대적 위치로 조정
    left: 20, // 왼쪽에서부터 20px 떨어진 곳에 위치
  },
  '& .MuiTypography-root': {
    margin: '0 auto', // 자동 마진을 이용하여 중앙에 위치
  }
});
function KakaoLoginButton() {
  const KAKAO_OAUTH2_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code&scope=profile_nickname,profile_image,account_email,openid`;

  const handleLogin = () => {
    window.location.href = KAKAO_OAUTH2_URL;
  };

  return (
    <StyledButton
      onClick={handleLogin}
      variant="contained"
      sx={{
        backgroundColor: 'rgba(251, 231, 80, 1)', 
        color: 'rgba(23, 23, 23, 1)',
        '&:hover': {
          backgroundColor: "rgba(251, 231, 80, 1)"
        },
        width: '100%',
        height: '64px',
        borderRadius: '12px',
        mb:2
      }}
      startIcon={<img src="/assets/kakao_logo.png" alt="Kakao" style={{ width: '22px', height: '22px' }} />}
    >
      <Typography sx={{ fontSize: '16px', fontWeight: 700, color: 'rgba(23, 23, 23, 1)' }}>
      카카오로 3초 만에 로그인
      </Typography>
    </StyledButton>
  );
}

export default KakaoLoginButton;