import React, { useState, useRef } from 'react';
import { Modal, IconButton, Box, Drawer, List, ListItem, ListItemText, ListItemAvatar, Button, Avatar, Typography, Paper, Divider, TextField, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Puller from './Puller';
import { icons } from '../../constants/icons';
const PostImageDetail = ({selectedImage, modalOpen, setModalOpen, title, artist, isDocent=false}) => {
  const COLLAPSED_HEIGHT = 120;
  const CHANGE_IMAGE_HEIGHT = 200;

  const [drawerHeight, setDrawerHeight] = React.useState(500);
  const [startY, setStartY] = React.useState(null);
  const [initialHeight, setInitialHeight] = React.useState(null); // 추가: 초기 높이 저장
  const [isDragging, setIsDragging] = React.useState(false);  // 추가
  const [isSend, setIsSend] = useState(false);
  const [chatText, setChatText] = useState(''); // 댓글 입력 상태
  const chatInputRef = useRef(null);


  const handleInputChange = (e) => {
    setChatText(e.target.value);
  };

  const handleSendClick = () => {
    // setIsSend(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (isSend) {
        event.preventDefault();
        handleSendClick();
      }
    }
  };
    const handleTouchMove = (e) => {
      if (!isDragging || !startY || !initialHeight) return;
      
      const currentY = e.touches[0].clientY;
      const deltaY = startY - currentY; // 움직인 거리
      const newHeight = initialHeight + deltaY; // 초기 높이에서 움직인 거리를 더함
      
      // 높이 제한
      const windowHeight = window.innerHeight;
      const minHeight = COLLAPSED_HEIGHT;
      const maxHeight = windowHeight * 0.9;
      const clampedHeight = Math.min(Math.max(newHeight, minHeight), maxHeight);
      
      setDrawerHeight(`${clampedHeight}px`);
  };

  const handleTouchStart = (e) => {
      e.preventDefault();
      const drawer = e.currentTarget.closest('.MuiDrawer-paper');
      const currentHeight = drawer.offsetHeight;
      
      setStartY(e.touches[0].clientY);
      setInitialHeight(currentHeight);
      setIsDragging(true);
  };

  const handleTouchEnd = () => {
      setStartY(null);
      setInitialHeight(null);
      setIsDragging(false);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const drawer = e.currentTarget.closest('.MuiDrawer-paper');
    const currentHeight = drawer.offsetHeight;
    
    setStartY(e.clientY);
    setInitialHeight(currentHeight);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !startY || !initialHeight) return;
    
    e.preventDefault();
    const deltaY = startY - e.clientY; // 움직인 거리
    const newHeight = initialHeight + deltaY; // 초기 높이에서 움직인 거리를 더함
    
    // 높이 제한
    const windowHeight = window.innerHeight;
    const minHeight = COLLAPSED_HEIGHT;
    const maxHeight = windowHeight * 0.9;
    const clampedHeight = Math.min(Math.max(newHeight, minHeight), maxHeight);
    
    setDrawerHeight(`${clampedHeight}px`);
  };

  const handleMouseUp = () => {
    setStartY(null);
    setInitialHeight(null);
    setIsDragging(false);
  };


  const renderChatMessage = (isAI, message) => (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'flex-start',
      gap: 2,
      mb: 2,
      px: 2,
      justifyContent: isAI ? 'flex-start' : 'flex-end' // 정렬 방향 수정
    }}>
      {isAI && ( // AI 메시지일 때만 아바타 표시
        <Avatar 
          sx={{ 
            bgcolor: '#E8F1FE',
            width: 36,
            height: 36
          }}
          src="assets/docent_profile.png"
        >
        </Avatar>
      )}
      <Paper 
        elevation={0}
        sx={{
          p: 2,
          maxWidth: '80%',
          bgcolor: isAI ? '#E8F1FE' : '#F5F5F5',
          borderRadius: 2
        }}
      >
        <Typography variant="body1">
          {message}
        </Typography>
      </Paper>
    </Box>
  );

  return <>
  <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100vw',  // 전체 너비로 변경
          height: '100vh', // 전체 높이로 변경
          backgroundColor: 'black', // 배경색 검정으로 설정
          outline: 'none',
          display: 'flex',
          alignItems: !isDocent ? 'center' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? 'flex-start' : 'center',
          justifyContent: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '120px',
            background: !isDocent ? 'none' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? 
              'linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)' : 'none',
            zIndex: 1
          }
        }}
      >
        <IconButton
          onClick={() => setModalOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)'
            },
            zIndex: 2  // z-index 추가
          }}
        >
          <CloseIcon sx={{ width: 24, height: 24 }}/>
        </IconButton>
        <Box
          sx={{
            height: !isDocent ? '100%' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? '100%' : 'calc(100vh - 200px)',
            width: '100%',
            display: 'flex',
            alignItems: !isDocent ? 'center' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? 'flex-start' : 'center',
            justifyContent: 'center'
          }}
        >
          <img
            src={selectedImage}
            style={{
              width: '100%',
              height: !isDocent ? '100%' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? 'auto' : '100%',
              objectFit: 'contain'
            }}
            alt="Enlarged view"
          />
        </Box>
        {isDocent && (
          <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <Drawer
          anchor="bottom"
          open={modalOpen}
          variant="persistent"  
          sx={{
            '& .MuiDrawer-paper': {
                position: 'relative',
                borderTopLeftRadius: 32,
                borderTopRightRadius: 32,
                borderColor: '#FFFFFF',
                backgroundColor: '#FFFFFF',
                height: drawerHeight,
                overflow: 'visible',
                transition: isDragging ? 'none' : 'height 0.3s ease-in-out', // 드래그 중에는 transition 비활성화
                maxHeight: '90vh',
            }
          }}
        >
           <Box
             onTouchStart={handleTouchStart}
             onTouchMove={handleTouchMove}
             onTouchEnd={handleTouchEnd}
             onMouseDown={handleMouseDown}
             onMouseUp={handleMouseUp}
             onMouseMove={handleMouseMove}  // 수정된 handleMouseMove 사용
             onMouseLeave={handleMouseUp} 
             sx={{ cursor: isDragging ? 'grabbing' : 'grab', userSelect: 'none', paddingTop: '8px'
             }}
           >
             <Puller />
           <Box sx={{ height: 16 }} />
           <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
            <Typography sx={{ fontSize: '24px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>{title}</Typography>
          </Box>
          <Box sx={{ width: '100%', textAlign: 'center'}}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(0,0,0,0.9)' }}>{artist}</Typography>
          </Box>

          <Divider sx={{ mx: 2, mt: 2}} />
          </Box>
          {drawerHeight === 'auto' || parseInt(drawerHeight) > COLLAPSED_HEIGHT ? (
            <>
            <Box sx={{ 
              flex: 1, 
              overflowY: 'auto',
              px: 2,
              pb: 2
            }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>  {/* 추가된 Box wrapper */}
                <Button 
                    variant="contained" 
                    sx={{
                        backgroundColor: '#EEF1FF',
                        color: '#4168F6',
                        fontWeight: 600,
                        fontSize: '14px',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        padding: '4px 12px',
                        minWidth: 'auto',
                        '&:hover': {
                            backgroundColor: '#E5E9FF',
                            boxShadow: 'none',
                        },
                    }} 
                >
                AI 도슨트
              </Button>
              </Box>
              {renderChatMessage(true, "이래 작품을 설명해 드릴게요. 질문을 직접 입력해 주세요.")}
              {renderChatMessage(false, "작품 설명")}
              {renderChatMessage(true, "이 작품은 1875년에 그려진 작품으로, 그의 아내와 아들이 등장합니다. 빛과 바람이 부드럽게 느껴지는 정원에서 인상주의 특징인 순간적인 빛과 색의 변화를 포착하고 있습니다. 이 작품은 일상적인 순간을 아름답게 담아낸 모네의 대표작 중 하나입니다.")}
            </Box>
            <Box display="flex" justifyContent="center" sx={{  pt: '16px', pb: '16px', pl: 2, pr: 2 }}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <TextField
                  value={chatText}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  multiline
                  maxRows={2}
                  placeholder="나의 생각을 댓글로 남겨주세요"
                  inputRef={chatInputRef}
                  sx={{
                    '.MuiInputBase-root': {
                      height: 'auto',
                      borderRadius: '12px',
                      backgroundColor: 'rgba(245, 247, 248, 1)',
                      padding: '0 14px', // 좌우 패딩 조정 (아이콘 공간 등 고려)
                      minHeight: '44px',
                      maxHeight: '88px',
                      overflow: 'auto', // 스크롤을 가능하게 함
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none' // 외곽선 제거
                    },
                    '.MuiInputBase-input': {
                      padding: '12px 0', // 패딩 조정
                      boxSizing: 'border-box', // 높이 계산을 위한 박스 모델 설정
                      position: 'relative',
                      zIndex: 2,
                      color: 'transparent', // 입력 텍스트 투명하게 설정
                      textShadow: 'none', // 텍스트 그림자 제거
                      caretColor: 'black', // 포커스 커서 색상 설정
                      fontSize: '14px', // TextField와 동일한 폰트 크기
                      fontWeight: 400, // TextField와 동일한 폰트 굵기
                      lineHeight: '20px', // TextField와 동일한 줄 높이
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton color="inherit" onClick={handleSendClick} disabled={!isSend}>
                        <img src={isSend ? icons.send.active : icons.send.inactive} alt="send" style={{ width: '24px', height: '24px' }} />
                      </IconButton>
                    )
                  }}
                />
                {/* <HighlightedText aria-hidden="true">
                  {getHighlightedText(commentText)}
                </HighlightedText> */}
              </Box>
            </Box>
            {/* <Box sx={{ 
              p: 2, 
              borderTop: '1px solid #eee',
              backgroundColor: '#fff'
            }}>
              <Box sx={{ 
                display: 'flex',
                gap: 1
              }}>
                <Box sx={{ 
                  flex: 1,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  p: 1
                }}>
                  <Typography color="text.secondary">
                    작품에 대해 궁금한 것을 입력하세요
                  </Typography>
                </Box>
                <Button 
                  variant="contained"
                  sx={{ 
                    minWidth: 'auto',
                    px: 3,
                    backgroundColor: '#000',
                    '&:hover': {
                      backgroundColor: '#333'
                    }
                  }}
                >
                  전송
                </Button>
              </Box>
            </Box> */}
            </>
            ) : null}
            </Drawer>
            </Box>
        )}
      </Box>
    </Modal>
    </>
}



export default PostImageDetail;