import { getKeywordRanking, getSearchKeyword, getMoreFeedList } from '../../api/SwissApi';
import { setPostList, morePostList ,setRanking } from './searchSlice';

export const fetchSearchKeyword = async (dispatch, keyword) => {
    try {
        const searchKeyword = await getSearchKeyword(keyword);
        console.log("fetchSearchKeyword: ",searchKeyword)
        dispatch(setPostList(searchKeyword));

    } catch (error) {
        console.error('Failed to fetch search keyword:', error);
    }
};

export const fetchMoreSearchKeyword = async (dispatch, nextUrl) => {
    try {
        const postPopularList = await getMoreFeedList(nextUrl);
        dispatch(morePostList(postPopularList));

    } catch (error) {
        console.error('Failed to fetch feed list:', error);
    }
}

export const fetchRankingKeyword = async (dispatch) => {
    try {
        const rankingKeyword = await getKeywordRanking();
        console.log("fetchSearchKeyword: ",rankingKeyword)
        dispatch(setRanking(rankingKeyword));

    } catch (error) {
        console.error('Failed to fetch search keyword:', error);
    }
};