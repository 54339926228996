import React, { useRef, useEffect, useState } from 'react';
import { Editor} from 'draft-js';
import { Typography, Button, Box } from '@mui/material';
import {createContentWithDecorator} from './editor/EditorDecorator';


function useUpdateEffect(effect, dependencies) {
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!isFirstRender.current) {
            return effect();
        }
        isFirstRender.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}

const PostCardText = ({content, rating, swiperRef}) => {
    const [isExpanded, setIsExpanded] = useState(false);  // 텍스트 확장 상태 관리
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [editorState, setEditorState] = useState(() => createContentWithDecorator(content, rating));

    const editorRef = useRef(null);

    useEffect(() => {
        setEditorState(createContentWithDecorator(content, rating));
    }, [content]);  
    

    useEffect(() => {
        if (editorRef.current) {
            const calculateHeight = () => {
                const lineHeight = parseInt(window.getComputedStyle(editorRef.current).lineHeight, 10);
                const maxHeight = lineHeight * 3;  // 세 줄 높이
                setShowMoreButton(editorRef.current.scrollHeight > maxHeight);
            };
            
            calculateHeight();  // 초기 높이 계산

            const observer = new ResizeObserver(calculateHeight);
            observer.observe(editorRef.current);

            return () => observer.disconnect();
        }
    }, [editorState]);  // editorState가 변경될 때마다 useEffect를 재실행

    useUpdateEffect(() => {
        const swiper = swiperRef.current?.swiper;
        if (swiper && isExpanded) {
            swiper.updateAutoHeight();
        }
    }, [isExpanded]);

    const handleExpandText = () => {
        setIsExpanded(true);  // 텍스트를 전체 보기로 확장
        setShowMoreButton(false);  // 버튼을 숨김
      };

    return (
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', p:2, pt:'12px', pb:'10px' }}>
            <Box ref={editorRef} sx={{ fontSize:'15px', fontWeight:400, lineHeight:'22px', color: 'rgba(0, 0, 0, 0.9)',
                maxHeight: isExpanded ? 'none' : '4.5em', // 3 lines approximately, depending on line height
                overflow: 'hidden',
                    // display: isExpanded ? 'block' : '-webkit-box',
                    // WebkitLineClamp: isExpanded ? 'none' : 3,
                    // WebkitBoxOrient: 'vertical'
            }}>
            <Editor
                readOnly={true}  // 에디터를 읽기 전용으로 설정
                editorState={editorState}
                onChange={setEditorState}  // 변경 사항을 state에 저장
            />
            </Box>
            {showMoreButton && (
                <Button 
                    onClick={handleExpandText} 
                    sx={{ 
                        position: 'absolute', 
                        right: 16, 
                        bottom: 8,
                        padding: 0, 
                        textTransform: 'none',
                        background: '#FFFFFF',
                        zIndex: 10,
                        color: '#737373',
                        fontSize: '15px',
                        fontWeight: 500,
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        }
                    }}>
                    {isExpanded ? '' : '...더보기'}
                </Button>
            )}
        </Box>
    );
};

export default PostCardText;