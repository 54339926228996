import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alert: [],
  },
  reducers: {
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
    addAlert: (state, action) => {
        state.alert = [...state.alert, action.payload]; // Append new posts
    },
    markAlertAsRead: (state, action) => {
        const alertId = action.payload;
        state.alert = state.alert.map((alert) =>
          alert.id === alertId ? { ...alert, is_read: true } : alert
        );
      },
  },
});

export const { setAlert, addAlert, markAlertAsRead } = alertSlice.actions;

export default alertSlice.reducer;