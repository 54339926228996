import { styled } from '@mui/material/styles';

const Puller = styled('div')(({ theme }) => ({
    width: 42,
    height: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.15)', // 검정색 배경에 투명도 15% 적용
    borderRadius: 3,
    position: 'absolute',
    top: 16,
    left: 'calc(50% - 18px)',
  }));

export default Puller;