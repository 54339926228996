import React, { useState } from 'react';
import { Box, Paper, Card, CardActionArea, CardMedia, IconButton, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function ImageUpload() {
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleImageChange = (event) => {
    if (imagePreviews.length >= 10) {
      // 이미지가 10개 이미 있을 경우 추가하지 않음
      alert('You can only upload up to 10 images.');
      return;
    }

    const files = Array.from(event.target.files);
    const newImageCount = files.length + imagePreviews.length;
    const allowedCount = 10 - imagePreviews.length; // 최대 10개까지 허용

    // 추가할 이미지와 기존 이미지의 합이 10개를 초과하는 경우 초과분 제거
    const filesToAdd = newImageCount > 10 ? files.slice(0, allowedCount) : files;
    const newImagePreviews = filesToAdd.map(file => URL.createObjectURL(file));

    setImages(prev => [...prev, ...filesToAdd]);
    setImagePreviews(prev => [...prev, ...newImagePreviews]);
  };

  const handleRemoveImage = (index) => {
    setImagePreviews(prev => prev.filter((_, i) => i !== index));
    setImages(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {imagePreviews.map((previewUrl, index) => (
          <Grid item xs={4} sm={3} md={2} key={index}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={previewUrl}
                  alt={`Preview ${index}`}
                  sx={{ height: 140, position: 'relative' }}
                />
                <IconButton
                  onClick={() => handleRemoveImage(index)}
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                >
                  <ClearIcon />
                </IconButton>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        <Grid item xs={4} sm={3} md={2}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-button-file"
            multiple
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="upload-button-file">
            <Paper
              variant="outlined"
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 140,
                borderStyle: 'dashed',
                borderColor: 'grey.400',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'action.hover',
                }
              }}
            >
              <IconButton color="primary" aria-label="upload picture" component="span">
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Paper>
          </label>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ImageUpload;