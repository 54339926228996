import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../api/apiClient';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MapComponent from './MapComponent';

function PlaceSearchMap() {
  const [placeInfo, setPlaceInfo] = useState({
    lat: 0,
    lng: 0,
    name: '',
    address: '',
    types:[],
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const placeId = searchParams.get('place_id');

  useEffect(() => {
    fetchMapDetailData();
  }, [placeId]);

  const fetchMapDetailData = () => {
    if (placeId) {
      api.get('/api/search/google/places/details', {
        params: { place_id: placeId },
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      .then(response => {
        if (response.status === 200 ) {
          const result = response.data.result;
          console.log(result);
          setPlaceInfo({
            name: result.name,
            address:result.formatted_address,
            lat: result.geometry.location.lat,
            lng: result.geometry.location.lng,
            types: result.types,
          }); // Set address from fetched data
        }
      })
      .catch(error => {
        console.error('Error fetching geocode:', error);
      });
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" color="default">
        <Toolbar sx={{ justifyContent: 'center', position: 'relative', textAlign: 'center' }}>
        <IconButton onClick={handleClose} edge="start" color="inherit" aria-label="back" sx={{ position: 'absolute', left: 16 }}>
            <ArrowBackIcon />
        </IconButton>
        <Box sx={{ textAlign: 'center', width: '100%' }}> {/* Box 전체를 중앙 정렬 */}
            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 600, color: 'rgba(0,0,0,0.85)' }}>
            {placeInfo.name}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0,0,0,0.85)' }}>
            {placeInfo.address}
            </Typography>
        </Box>
        </Toolbar>
    </AppBar>
    <MapComponent place={placeInfo} />
    </Box>
  );
}

export default PlaceSearchMap;