import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../repository/user/userSlice';
import api from '../../api/apiClient';

function HandleRedirectPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { social } = useParams();

    // 소셜 로그인 처리 함수
    const handleSocialLogin = (socialType, code) => {
        api.post(`/api/auth/${socialType}`, { token: code })
            .then(response => {
                // 헤더에서 Authorization 토큰 추출
                const authToken = response.headers.get('authorization');
                if (authToken) {
                    // 로컬 스토리지에 토큰 저장
                    localStorage.setItem('authToken', authToken);
                }
                const { user_id: userId, username, profile_image_url: profileImageUrl } = response.data;
                dispatch(setUser({ userId, username, profileImageUrl }));
                navigate('/main');
            })
            .catch(error => {
                console.error('Error converting token:', error.response);
                // 에러 처리
            });
    };
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code && ['google', 'kakao','naver'].includes(social)) {
            handleSocialLogin(social, code);
        }
    }, []);

    return <div>Redirecting...</div>;
}

export default HandleRedirectPage;