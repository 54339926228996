import React, { useEffect, useRef, useState } from 'react';
import api from '../../api/apiClient';

/* global google */

function GoogleMap({place}) {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [address, setAddress] = useState('주소를 검색 중...');

  useEffect(() => {
    const {lat, lng } = place;
    window.initMap = function() {
      const center = { lat, lng };
      const mapOptions = {
        zoom: 17,
        center: center,
        mapId: '99bdb9b2d6da52a7'
      };
      const mapInstance = new window.google.maps.Map(mapContainerRef.current, mapOptions);
      setMap(mapInstance);
       geocodeLatLng(lat, lng, mapInstance);
    };

    function loadGoogleMapsScript() {
      const scriptId = 'google-maps-script';
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMap&libraries=marker`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      }
    }

    loadGoogleMapsScript();

    return () => {
      const script = document.getElementById('google-maps-script');
      if (script) {
        document.head.removeChild(script);
      }
      delete window.initMap;
    };
  }, [place]);

  const geocodeLatLng = (lat, lng, map) => {
    const marker = new google.maps.Marker({
      position: { lat, lng },
      map: map,
      title: place.address
    });

    const infoWindow = new google.maps.InfoWindow({
      content: `<div><h3>${place.name}</h3></div><div><strong>Location:</strong> ${place.address}</div>`
    });

    // Open the info window immediately without waiting for a marker click
    infoWindow.open(map, marker);

    // Optionally, you can still add a listener if you want the window to open again on click
    marker.addListener('click', () => {
      infoWindow.open(map, marker);
    });

  };

  return <div ref={mapContainerRef} style={{ height: '600px', width: '100%' }} />;
}

export default GoogleMap;