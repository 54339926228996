import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, AppBar, Toolbar, TextField, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Editor, EditorState, Modifier,CompositeDecorator,ContentState } from "draft-js";
import "draft-js/dist/Draft.css";
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/apiClient';
import {updateEditorImageAtIndex, removeEditorImage, clearEditors, addEditor, setEditorType, setLoading} from '../../repository/posts/postsSlice';
import axios from 'axios';
import {NoRatingDecorator, CustomPlaceholder} from '../../common/components/editor/EditorDecorator';


const MAX_IMAGES = 10;
const TYPE_INIT = 'init';
const TYPE_ADD = 'add';

const QuestionEditForm = ({content}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState(['example', 'hashtag', 'draftjs']);
  const [editorState, setEditorState] = useState(() =>
      EditorState.createEmpty(NoRatingDecorator)
  );
  const editor = React.useRef(null);


  useEffect(()=>{
    // dispatch(setEditorType("feed"));
  },[]);
  useEffect(() => {
    if(content) {
        const newContentState = ContentState.createFromText(`${content}`);
        setEditorState(EditorState.createWithContent(newContentState, NoRatingDecorator));
    }
}, []);

const handleEditorChange = (editorState) => {
    console.log(editorState)
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText();
    const lastChar = plainText.slice(-1);
    if (lastChar === '#') {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
    setEditorState(editorState);
    // handleChange(plainText);
  };

    const handleFocus = () => {
        setIsFocused(true);
    };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const focusEditor = () => {
        editor.current.focus();
    };

//   useEffect(() => {
//     console.log('showPhotoEdit: ',showPhotoEdit)
//     if (editorImage == "") {
//       setShowPhotoEdit(false);
//     } else {
//       setShowPhotoEdit(true);
//       resetFileInput();
//     }
//   }, [editorsCount, editorImage]);

  const handleSubmit = async () => {
    // const filterEditor = editors.filter((editor, index, array) => {
    //   return (editor.content !== "" || editor.image_url !== "") || (index === array.length - 1 && (editor.content !== "" || editor.image_url !== ""));
    // });
    // console.log(filterEditor)
    // const regitEditorParam = {
    //   user_id : userId,
    //   type: editorType,
    //   posts : filterEditor
    // }
    // try {
    //   const response = await regitEditor(regitEditorParam);
    //   console.log('Submitted:', response.data); // Axios wraps the response data in the 'data' property
    //   // or handle navigation based on response
    //   navigate('/'); 
    //   dispatch(clearEditors());
    // } catch (error) {
    //   console.error('Submit Error:', error.response ? error.response.data : error.message);
    //   // It's good practice to check for error.response to ensure error details are captured correctly
    // }
  };

  const handleClose = () => {
    navigate(-1);
  };

  // handlePhotoEdit 함수에 type 파라미터 추가
//   const handlePhotoEdit = (type) => {
//     openFileInput(type);
//   };

  // openFileInput 함수에 type 파라미터 추가 및 fileInputRef에 type 저장
//   const openFileInput = (type) => {
//     console.log(type)
//     fileInputRef.current.input_type = type;  // type을 현재 선택한 input의 ref에 저장
//     fileInputRef.current.click();
//   };

//   const handleChangeImage = async (event) => {
//     console.log('handleChangeImage');
//     const files = Array.from(event.target.files);
//     const type = fileInputRef.current.input_type;

//     if (files.length > MAX_IMAGES) {
//       alert(`You can only upload up to ${MAX_IMAGES} images.`);
//       return;
//     }
//     setLoading(true);  // 로딩 시작

//     try {
//         if (type === TYPE_INIT) {
//             await handleInitType(files);
//         } else if (type === TYPE_ADD) {
//             await handleAddType(files);
//         }
//     } catch (error) {
//         console.error('Error processing files:', error);
//     } finally {
//       setLoading(false);  // 작업 완료 후 로딩 종료
//     }

//     dispatch(addEditor({image_url: ""}));
//   };
  
//   const handleInitType = async (files) => {
//     for (const [index, file] of files.entries()) {
//         const imageUrl = await uploadImage(file);
//         dispatch(updateEditorImageAtIndex({ index, image_url: imageUrl }));
//     }
//   };

//   const handleAddType = async (files) => {
//       for (const [index, file] of files.entries()) {
//           const imageUrl = await uploadImage(file);
//           if (index === 0) {
//               dispatch(updateEditorImageAtIndex({ index: editorsCount - 1, image_url: imageUrl }));
//           } else {
//               dispatch(addEditor({ image_url: imageUrl }));
//           }
//       }
//   };

//   const uploadImage = async (image) => {
//     const uploadUrl = await getSignedUrl(image.name);
//     await axios.put(uploadUrl, image, {
//         headers: { 'Content-Type': 'application/octet-stream' }
//     });

//     return getPublicUrl(image.name);
//   };

  const getSignedUrl = async (fileName) => {
      const response = await api.get(`/api/posts/images/signed-url?file_name=${encodeURIComponent(fileName)}`);
      return response.data.upload_url;
  };

  const getPublicUrl = async (fileName) => {
      const response = await api.get(`/api/posts/images/public-url?file_name=${encodeURIComponent(fileName)}`);
      return response.data.public_url;
  };

  const regitEditor = async (editor) => {
    const response = await api.post(`/api/posts`, editor);
    return response.data;
  }

//   const resetFileInput = () => {
//     if (fileInputRef.current) {
//       fileInputRef.current.value = "";
//     }
//   };

//   const handleRemoveImage = (index) => {
//     dispatch(removeEditorImage(index));
//   };


  return (
    <Box sx={{
      width: '100%',
      maxWidth: '800px',
      mx: 'auto',
      border:0,
      p:2,
      pl:'20px',
      boxSizing: 'border-box',
      '@media (min-width: 800px)': {
        margin: '0 auto',
      }
    }}>
        <AppBar position="static" sx={{
            backgroundColor: '#ffffff', 
            elevation: 0, // Remove shadow
            p:0,
            margin:0,
            boxShadow: 'none', // Additional reinforcement to remove shadows
            border: 0 // Ensure no borders
        }}>
        <Toolbar sx={{ justifyContent: 'space-between',p:0, margin:0}}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ color: 'black' }}>
            <CloseIcon />
          </IconButton>
          <Button color="inherit" onClick={handleSubmit} sx={{ color: '#000000', fontSize:'16px',fontWeight:600 }}>
            완료
          </Button>
        </Toolbar>
      </AppBar>
      <Box>
        <TextField 
        placeholder="제목을 입력해주세요." 
        fullWidth rows={1}
        sx={{
            width: '100%', // 필요하면 너비 조정
            '.MuiInputBase-root': {
            height: '55px',
            // backgroundColor: 'rgba(245, 247, 248, 1)'
            },
            '.MuiOutlinedInput-notchedOutline': {
            border: 'none' // 외곽선 제거
            },
            '.MuiInputBase-input': {
            height: '100%',
            padding: '0 14px' // 좌우 패딩 조정 (아이콘 공간 등 고려)
            }
        }}/>
      </Box>
      <Divider/>
      <Box>
            <Box sx={{position: 'relative', padding:1,   
            border: isFocused ? "0px" : "0px solid transparent", // Change color when focused
            minHeight:'300px', cursor: "text"}} onClick={focusEditor}>
            <CustomPlaceholder type={''} editorState={editorState} isFocused={isFocused}/>
            <Editor
            ref={editor}
            editorState={editorState}
            onChange={handleEditorChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            />
            </Box>
        </Box>
    </Box>
  );
};

export default QuestionEditForm;


