import { getProfiles, getFollwers, postFollwer, deleteFollwer } from '../../api/SwissApi';
import { setProfile, setFollower, setIsFollow, addFollower, removeFollower } from './profilesSlice';

export const fetchProfiles = async (dispatch, userId) => {
    try {
        const profiles = await getProfiles(userId);
        
        dispatch(setProfile(profiles));

    } catch (error) {
        console.error('Failed to fetch profiles:', error);
    }
};

export const fetchFollowers = async (dispatch, userId) => {
    try {
        const followers = await getFollwers(userId);
        dispatch(setFollower(followers));

    } catch (error) {
        console.error('Failed to fetch add follower:', error);
    } 
}

export const fetchAddFollower = async (dispatch, userId) => {
    try {
        const response = await postFollwer(userId);
        dispatch(addFollower(response));
    } catch (error) {
        console.error('Failed to fetch add follower:', error);
    } 
}

export const fetchDeleteFollower = async (dispatch, userId) => {
    try {
        await deleteFollwer(userId);
        dispatch(removeFollower(userId));
    } catch (error) {
        console.error('Failed to fetch delete followers:', error);
    } 
}