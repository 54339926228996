import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    loading: false,
    next : null,
    count : 0,
    posts: [],
    ranking: [],
  },
  reducers: {
    setPostList: (state, action) => {
      state.posts = action.payload.results;
      state.next = action.payload.next;
      state.count = action.payload.count;
    },
    morePostList: (state, action) => {
      state.posts = [...state.posts, ...action.payload.results]; // Append new posts
      state.next = action.payload.next;
    },
    setRanking: (state, action) => {
      state.ranking = action.payload;
    }
    },
  
});

// Action creators are generated for each case reducer function
export const { setPostList, morePostList, setRanking} = searchSlice.actions;

export default searchSlice.reducer;