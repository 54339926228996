export const icons = {
    send: {
      active: '/assets/icons/comments/active/send.png',
      inactive: '/assets/icons/comments/inactive/send.png'
    },
    placeBookmark: {
        active: '/assets/icons/active/push_pin.png',
        inactive: '/assets/icons/inactive/push_pin.png'
    },
    like: {
      active: '/assets/icons/active/heart.png',
      inactive: '/assets/icons/inactive/heart.png'
    },
    comments: {
      active: '/assets/icons/comment.png',
      inactive: '/assets/icons/comment.png'
    },
    share: {
      active: '/assets/icons/share.png',
      inactive: '/assets/icons/share.png'
    },
    smallBookmarks: {
      active: '/assets/icons/active/bookmark-24.png',
      inactive: '/assets/icons/inactive/bookmark-24.png'
    },
    send: {
      active: '/assets/icons/comments/active/send.png',
      inactive: '/assets/icons/comments/inactive/send.png'
    },
    home: {
      active: '/assets/icons/active/home.png',
      inactive: '/assets/icons/inactive/home.png'
    },
    search: {
      active: '/assets/icons/active/search.png',
      inactive: '/assets/icons/inactive/search.png'
    },
    edit: {
      active: '/assets/icons/active/writing.png',
      inactive: '/assets/icons/inactive/writing.png'
    },
    profile: {
      active: '/assets/icons/active/user.png',
      inactive: '/assets/icons/inactive/user.png'
    },
    bookmarks: {
      active: '/assets/icons/active/bookmark.png',
      inactive: '/assets/icons/inactive/bookmark.png'
    },
};