import api from './apiClient';

export const postComments = async (postGroupId, bodyParam) => {
    const response = await api.post(`/api/posts/${postGroupId}/comments`, bodyParam);
    return response.data;
};

export const getComments = async (postGroupId) => {
    const response = await api.get(`/api/posts/${postGroupId}/comments`);
    return response.data;
  };

export const postFeed = async (feed) => {
    const response = await api.post(`/api/posts`, feed);
    return response.data;
};

export const getFeedList = async (type='feed') => {
    const response = await api.get(`/api/posts?type=${type}`);
    return response.data;
};

export const getMoreFeedList = async (nextUrl) => {
    const response = await api.get(`${nextUrl}`);
    return response.data;
}

export const getPostDetail = async (postGroupId) => {
    const response = await api.get(`/api/posts/${postGroupId}`)
    return response.data;
}

export const getPostPopular = async () => {
    const response = await api.get(`/api/posts/popular`);
    return response.data;
}
     
export const getBookmarks = async (type) => {
    const response = await api.get(`/api/bookmarks?type=${type}`);
    return response.data;
}; 

export const postBookmarks = async (type, targeId) => {
    const response = await api.post(`/api/bookmarks`, { target_id: targeId, type: type });
    return response.data;
}

export const deleteBookmarks = async (type, targetId) => {
    const response = await api.delete(`/api/bookmarks/${targetId}?type=${type}`);
    return response.data;
}

export const getFollwers = async (userId, type = 'followed') => {
    const response = await api.get(`/api/followers/${userId}?type=${type}`);
    return response.data;
}

export const postFollwer = async (userId) => {
    const response = await api.post(`/api/followers`, { followed_id : userId});
    return response.data;
}

export const deleteFollwer = async (userId) => {
    const response = await api.delete(`/api/followers?followed_id=${userId}`);
    return response.data;
}

export const getProfiles = async (userId) => {
    const response = await api.get(`/api/profiles/${userId}`);
    return response.data;
}

export const getSignedUrl = async (fileName) => {
    const response = await api.get(`/api/posts/images/signed-url?file_name=${encodeURIComponent(fileName)}`);
    return response.data;
};

export const getPublicUrl = async (fileName) => {
    const response = await api.get(`/api/posts/images/public-url?file_name=${encodeURIComponent(fileName)}`);
    return response.data;
};

export const postGemini = async (imageUrl) => {
    const response = await api.post(`/api/gemini/generate`, {'image_url':imageUrl});
    return response.data;
}

export const getSearchKeyword = async (keyword) => {
    const response = await api.get(`/api/search/keyword?keyword=${keyword}`);
    return response.data;
}

export const getKeywordRanking = async () => {
    const response = await api.get(`/api/keywordRanking`);
    return response.data;
}

export const getAlert = async () => {
    const response = await api.get(`/api/notify`);
    return response.data;
}

export const readAlert = async (alertId) => {
    const response = await api.post(`/api/notify/${alertId}/read`);
    return response.data;
}

export const getUserInfo = async () => {
    const response = await api.get(`/api/auth/user-info`);
    return response.data;
}