import React,{useState, useEffect} from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Editor, EditorState, Modifier,CompositeDecorator,ContentState } from "draft-js";
import "draft-js/dist/Draft.css";
import {NoRatingDecorator, CustomPlaceholder} from '../../common/components/editor/EditorDecorator';
  
const PostEditor = ({type, contentIndex, content, handleChange, minHeight='50px'}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState(['example', 'hashtag', 'draftjs']);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(NoRatingDecorator)
    );
    const editor = React.useRef(null);

    useEffect(() => {
        if(content) {
            const newContentState = ContentState.createFromText(`${content}`);
            setEditorState(EditorState.createWithContent(newContentState, NoRatingDecorator));
        }
    }, []);

    const handleEditorChange = (editorState) => {
        console.log(editorState)
        const currentContent = editorState.getCurrentContent();
        const plainText = currentContent.getPlainText();
        const lastChar = plainText.slice(-1);
        if (lastChar === '#') {
          setShowSuggestions(true);
        } else {
          setShowSuggestions(false);
        }
        setEditorState(editorState);
        handleChange(plainText);
      };

    const handleFocus = () => {
        setIsFocused(true);
      };
      
    const handleBlur = () => {
        setIsFocused(false);
    };

    const focusEditor = () => {
        editor.current.focus();
    };

    return (
        <Box>
            <Box sx={{position: 'relative', padding:1,   
            border: isFocused ? "0px" : "0px solid transparent", // Change color when focused
            minHeight:minHeight, cursor: "text"}} onClick={focusEditor}>
            <CustomPlaceholder type={type} editorState={editorState} isFocused={isFocused}/>
            <Editor
            ref={editor}
            editorState={editorState}
            onChange={handleEditorChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            />
            </Box>
        </Box>
    )
}

export default PostEditor;