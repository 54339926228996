import { getAlert, readAlert } from '../../api/SwissApi';
import { setAlert, markAlertAsRead } from './alertSlice';

export const fetchAlert = async (dispatch) => {
    try {
        const alertList = await getAlert();
        dispatch(setAlert(alertList));

    } catch (error) {
        console.error('Failed to fetch get alert:', error);
    }
};

export const fetchReadAlert = async (dispatch, alertId) => {
    try {
        const alert = await readAlert(alertId);
        console.log('fetchReadAlert: ', alert);
        dispatch(markAlertAsRead(alertId));
    } catch (error) {
        console.error('Failed to fetch read alert:', error);
    }
};

