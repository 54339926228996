import { createSlice } from '@reduxjs/toolkit';

export const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState: {
    location: [],
    post_group: [],
  },
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setPostGroup: (state, action) => {
      state.post_group = action.payload;
    },
    removeLocation: (state, action) => {
      state.location = state.location.filter(location => location.id !== action.payload);
    },
    removePostGroup: (state, action) => {
      state.post_group = state.post_group.filter(post => post.id !== action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLocation, setPostGroup, removeLocation, removePostGroup} = bookmarksSlice.actions;

export default bookmarksSlice.reducer;